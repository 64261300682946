import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useGlobal } from "./globalContext";
import { RiAddBoxLine, RiCheckLine } from "@remixicon/react";
import { Dialog, DialogPanel } from "@tremor/react";
import { Card, CardHeader, CardBody, CardFooter, Divider, Textarea, Input, Chip, Button } from "@nextui-org/react";
import { DatePicker } from "@tremor/react";
import { fr } from "date-fns/locale";
import { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTitle, DrawerTrigger } from "./shadcn/DrawerComponent"
import { CircleArrowRight, CircleArrowLeft } from "lucide-react";


const ClientsDetails = ({ id }) => {

	const [clientData, setClientData] = useState({});
	const { checkDataEmpty, checkDataValid,
			handleObjectivesPrint, handleTypePrint, handleAdjectivesPrint,
			stateColors, stateNames, getPreviousState, getNextState,
			setShowAlert, setAlertMessage } = useGlobal();
	const [isLoading, setIsLoading] = useState(true);
	const [clientNotes, setClientNotes] = useState({});
	const [showNotesDialog, setShowNotesDialog] = useState(false);
	const [newNote, setNewNote] = useState(false);
	const [newNoteDate, setNewNoteDate] = useState('');
	const [currentNote, setCurrentNote] = useState('');
	const [currentNoteDate, setCurrentNoteDate] = useState('');
	const [existingNoteId, setExistingNoteId] = useState(-1);
	const [noteTitle, setNoteTitle] = useState('');
	const [deadlineDate, setDeadlineDate] = useState(null)
	const [tempState, setTempState] = useState("");
	


	useEffect(() => {
		getClientData();
	}, [id]);


	const getClientData = async () => {
		try {
			const response = await axios.get(`/api/getClient/${id}/`);
			if (response.data.success) {
				setClientData(response.data.client[0]);
				setClientNotes(response.data.client[0].notes);
				setTempState(response.data.client[0].state);
				if (response.data.client[0].nextDeadline !== null) {
					const convertedDate = convertBackendDateToFrontendDate(response.data.client[0].nextDeadline);
					setDeadlineDate(convertedDate);
				} else {
					setDeadlineDate(null);
				}
				setIsLoading(false);
			} else {
				throw new Error('Une erreur inattendue est survenue. Veuillez réessayer plus tard.');
			}
		} catch (error) {
			const errorMessage = error.response ? error.response.data.message : error.message;
			alert(errorMessage);
		}
	}


	const handleNewDeadline = async () => {
		console.log(deadlineDate);
		let deadline = null;
		if (deadlineDate === undefined || deadlineDate === null) {
			deadline = "none";
		} else {
			deadline = convertFrontendDateToBackendDate(deadlineDate);
		}
		try {
			const data = {
				"key": id,
				"nextDeadline": deadline
			}
			const response = await axios.post(`/api/saveNextDeadline/`, data);
			if (response.data.success) {
				getClientData();
				setShowAlert("success");
				setAlertMessage("La deadline a bien été enregistrée.");
				const interval = setInterval(() => {
					setShowAlert("none");
					setAlertMessage("");
					clearInterval(interval);
				}, 1500);
			}
		}
		catch (error) {
			const errorMessage = error.response ? error.response.data.message : error.message;
			alert(errorMessage);
		}
	}


	function convertBackendDateToFrontendDate(dateStr) {
		const [datePart, timePart] = dateStr.split(' ');
		const [day, month, year] = datePart.split('/').map(Number);
		const [hours, minutes, seconds] = timePart.split(':').map(Number);
		return new Date(year, month - 1, day, hours, minutes, seconds);
	}


	function convertFrontendDateToBackendDate(date) {
		const day = date.getDate().toString().padStart(2, '0');
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const year = date.getFullYear();
		const hours = date.getHours().toString().padStart(2, '0');
		const minutes = date.getMinutes().toString().padStart(2, '0');
		const seconds = date.getSeconds().toString().padStart(2, '0');
		return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
	}


	const handleNewNote = () => {
		setNewNote(true);
		setCurrentNote('');
		setNoteTitle('');
		setNewNoteDate(new Date().toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false}));
		setShowNotesDialog(true);
	}


	const handleExistingNote = (note) => {
		setNewNote(false);
		setExistingNoteId(note.id);
		setCurrentNoteDate(note.createdDate);
		setCurrentNote(note.notes);
		setNoteTitle(note.title);
		setShowNotesDialog(true);
	}


	const handleNoteChange = (newNote) => {
		setCurrentNote(newNote);
	}


	const handleNewNoteSave = async () => {
		let data = {};
		if (newNote) {
			data = {
				"key": id,
				"note" : {
					"id": -1,
					"createdDate": newNoteDate,
					"updatedDate": newNoteDate,
					"title": noteTitle,
					"notes": currentNote
				}
			}
		} else {
			data = {
				"key": id,
				"note" : {
					"id": existingNoteId,
					"createdDate": currentNoteDate,
					"updatedDate": new Date().toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false}),
					"title": noteTitle,
					"notes": currentNote
				}
			}
		}
		try {
			const response = await axios.post(`/api/saveClientNotes/`, data);
			if (response.data.success) {
				getClientData();
				setCurrentNote('');
				setNoteTitle('');
				setShowNotesDialog(false);
			}
		}
		catch (error) {
			const errorMessage = error.response ? error.response.data.message : error.message;
			alert(errorMessage);
		}
	}


	const handleDeleteNote = async () => {
		try {
			const data = {
				"key": id,
				"note" : {
					"id": existingNoteId,
				}
			}
			const response = await axios.post(`/api/deleteClientNotes/`, data);
			if (response.data.success) {
				await getClientData();
				setCurrentNote('');
				setNoteTitle('');
				setShowNotesDialog(false);
			}
		}
		catch (error) {
			const errorMessage = error.response ? error.response.data.message : error.message;
			alert(errorMessage);
		}
	}


	function parseDateStr(dateStr) {
		const parts = dateStr.split(' ');
		const dateParts = parts[0].split('/');
		const timeParts = parts[1].split(':');
		return new Date(dateParts[2], dateParts[1] - 1, dateParts[0], timeParts[0], timeParts[1], timeParts[2]);
	}


	const timeLeft = deadlineDate ? Math.floor((deadlineDate - new Date()) / (1000 * 60 * 60 * 24)) + 1 : 'Non défini';


	const handleStateDrawer = () => {
		console.log('State drawer');
		console.log(clientData.state);
	}


	const handlePreviousTempState = () => {
		setTempState(getPreviousState(tempState));
	}


	const handleNextTempState = () => {
		setTempState(getNextState(tempState));
	}


	return (
		<>
		{ !isLoading &&
			<div className="grid grid-rows-24 grid-cols-24 w-full h-full gap-2 p-2">
				<div className="row-span-6 col-start-9 col-span-8 flex flex-col justify-center items-center bg-white rounded-lg">
					<img src="https://via.placeholder.com/75" alt="client" className="rounded-full" />
					<p className="tuco-undertitle-2">{clientData.restaurantName}</p>
					<div className="grid grid-cols-12 justify-center items-center">
						<div className="col-span-6 container-col">
							<p className="tuco-text text-center underline">Prénom</p>
							<p className="tuco-undertext text-center">{checkDataEmpty(clientData.firstName)}</p>
						</div>
						<div className="col-span-6 container-col">
							<p className="tuco-text text-center underline">Nom</p>
							<p className="tuco-undertext text-center">{checkDataEmpty(clientData.lastName)}</p>
						</div>
						<div className="col-span-6 container-col">
							<p className="tuco-text text-center underline">Email</p>
							<p className="tuco-undertext text-center">{checkDataEmpty(clientData.email)}</p>
						</div>
						<div className="col-span-6 container-col">
							<p className="tuco-text text-center underline">Téléphone</p>
							<p className="tuco-undertext text-center">{checkDataEmpty(clientData.phone)}</p>
						</div>
					</div>
				</div>
				<div className="row-span-6 col-start-19 col-span-6 flex flex-col justify-between items-center bg-white rounded-lg">
					<p className="tuco-undertitle-2 font-bold py-2 bg-tremor-tuco-green-smooth w-full rounded-lg rounded-b-2xl">Liens</p>
					
				</div>
				<div className="row-span-18 col-span-12 bg-white rounded-lg grid grid-rows-8 grid-cols-12 justify-center items-center gap-2 p-1">
					<div className="row-span-3 col-span-12 h-full border-2 border-tremor-tuco-green-smooth rounded-lg container-col justify-start overflow-y-auto">
						<div className="container-row flex-wrap justify-start w-full gap-2 px-2 py-2">
							<p className="tuco-undertitle-2 font-bold">Objectifs : </p>
							<p className="tuco-text pt-1">{handleObjectivesPrint(clientData.objectives)}</p>
						</div>
						<div className="container-row flex-wrap justify-start w-full gap-2 px-2 py-2">
							<p className="tuco-undertitle-2 font-bold">Type :</p>
							<p className="tuco-text pt-1">{handleTypePrint(clientData.style)}</p>
						</div>
						<div className="container-row flex-wrap justify-start w-full gap-2 px-2 py-2">
							<p className="tuco-undertitle-2 font-bold">Adjectifs : </p>
							<p className="tuco-text pt-1">{handleAdjectivesPrint(clientData.adjectives)}</p>
						</div>
						<div className="container-row flex-wrap justify-start w-full gap-2 px-2 py-2">
							<p className="tuco-undertitle-2 font-bold">Concept : </p>
							<p className="tuco-text pt-1">{checkDataEmpty(clientData.concept)}</p>
						</div>
						<div className="container-row flex-wrap justify-start w-full gap-2 px-2 py-2">
							<p className="tuco-undertitle-2 font-bold">Identité : </p>
							<p className="tuco-text pt-1">{checkDataEmpty(clientData.identity)}</p>
						</div>
					</div>
					<div className="row-span-3 col-span-12 h-full border-2 border-tremor-tuco-mustard rounded-lg container-row flex-wrap gap-2 overflow-y-auto p-2 items-start">
						<Card isPressable onPress={handleNewNote} className="w-full h-1/4">
							<div className="container-row justify-between items-center w-full h-full px-4">
								<RiAddBoxLine size="24px" />
								<div className="flex-grow"></div>
								<p className="tuco-undertitle-2 font-bold place-content-center">Ajouter une note</p>
								<div className="flex-grow"></div>
							</div>
						</Card>
						{ clientNotes.length > 0 && clientNotes.sort((a, b) => parseDateStr(b.updatedDate) - parseDateStr(a.updatedDate)).map((note) => (
							<Card isPressable key={note.id} className="w-full h-1/4" onPress={() => {handleExistingNote(note)}}>
								<div className="container-row justify-between items-center w-full h-full px-4">
									<div className="flex-grow"></div>
									<p className="tuco-undertitle-2 font-bold">{note.title}</p>
									<div className="flex-grow"></div>
									<p className="tuco-text">{note.updatedDate}</p>
									<div className="flex-grow"></div>
								</div>
							</Card>
						))}
					</div>
					<div className="row-span-2 col-span-12 h-full border-2 border-tremor-tuco-blue rounded-lg">

					</div>
				</div>
				<div className="row-span-18 col-span-12 bg-white rounded-lg grid grid-rows-8 grid-cols-12 justify-center items-center gap-2 p-1">
					<div className="row-span-2 col-span-12 h-full border-2 border-tremor-tuco-blue rounded-lg container-col gap-1">
						<Drawer className="h-fit">
							<DrawerTrigger asChild>
								<Chip onClick={handleStateDrawer} className="cursor-pointer" classNames={{content: "text-white text-sm"}} style={{ backgroundColor: stateColors[clientData.state] }}>{stateNames[clientData.state]}</Chip>
							</DrawerTrigger>
							<DrawerContent>
								<div className="mx-auto maw-w-sm container-col">
									<DrawerHeader>
										<DrawerTitle>Changer l'état</DrawerTitle>
										<DrawerDescription>Choisir un nouvel état pour le client</DrawerDescription>
									</DrawerHeader>
									<div className="container-row gap-2 py-4">
										{ tempState === "maquette" ?  ( <div className="flex flex-grow w-[24px]"></div> ) : ( <Button isIconOnly onClick={handlePreviousTempState} className="focus:outline-none" variant="light"><CircleArrowLeft size={24} /></Button> )}
										<Chip classNames={{content: "text-white text-lg"}} style={{ backgroundColor: stateColors[tempState] }}>{stateNames[tempState]}</Chip>
										{ tempState === "done" ?  ( <div className="flex flex-grow w-[24px]"></div> ) : ( <Button isIconOnly onClick={handleNextTempState} className="focus:outline-none" variant="light"><CircleArrowRight size={24} /></Button> )}
									</div>
									<DatePicker className="py-4" locale={fr} />
									<div className="container-col gap-1 w-full py-2">
										<button className="btn-tuco-transparent w-full">Enregistrer</button>
										<DrawerClose asChild>
											<button className="btn-tuco-black w-full">Annuler</button>
										</DrawerClose>
									</div>
								</div>
							</DrawerContent>
						</Drawer>
						<div className="container-row w-full justify-center items-center gap-2 px-4">
							<p className="tuco-text maw-w-xs">Prochaine deadline : </p>
							<DatePicker locale={fr} value={deadlineDate} onValueChange={setDeadlineDate} />
							<Button isIconOnly className="cursor-pointer focus:outline-none" variant="faded" color="success" onClick={handleNewDeadline}><RiCheckLine className="cursor-pointer" size={24} /></Button>
						</div>
						<p className="tuco-text">{timeLeft === "Non défini" ? null : `Temps restant : ${timeLeft} ${timeLeft === 1 ? 'jour' : 'jours'}`}</p>
					</div>
				</div>
			</div>
		}
			<Dialog open={showNotesDialog} onClose={() => setShowNotesDialog(false)} static={true} className="z-[100] max-h-[70%]">
				<DialogPanel className="container-col gap-2">
					{newNote &&
						<>
							<p className="tuco-undertitle-2 font-bold">Ajouter une note</p>
							<p className="tuco-text">Date : {newNoteDate}</p>
						</>
					}
					{!newNote &&
						<>
							<p className="tuco-undertitle-2 font-bold">Modifier une note</p>
							<p className="tuco-text">Date : {currentNoteDate}</p>
						</>
					}
					<div className="container-row justify-between items-center gap-2 whitespace-nowrap">
						<p className="tuco-text">Titre :</p>
						<Input value={noteTitle} onValueChange={setNoteTitle} />
					</div>
					<Textarea classNames={{base: "w-full", input: "resize-y min-h-[90px]",}} value={currentNote} onChange={(e) => handleNoteChange(e.target.value)} />
					<div className="pt-4 flex gap-2">
						<button className="btn-tuco-green" onClick={handleNewNoteSave}>Enregistrer</button>
						{!newNote &&
							<button className="btn-tuco-red" onClick={handleDeleteNote}>Supprimer</button>
						}
					</div>
				</DialogPanel>
			</Dialog>
		</>
	);
}

export default ClientsDetails;