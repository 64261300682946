import React, {useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import{ Link, useParams} from "react-router-dom";
import { Grid } from "@mui/material";
import {Card, CardHeader, CardBody, CardFooter} from "@nextui-org/card";
import { Textarea } from "@nextui-org/input";
import { Tuco } from "../misc/Tuco";
import { Divider, TextInput, NumberInput, Select, SelectItem, MultiSelect, MultiSelectItem } from "@tremor/react";

export default function FirstContact() {

	const [name, setName] = useState("");
	const nameRef = useRef();
	const [nameError, setNameError] = useState(false);
	
	const [restaurantType, setRestaurantType] = useState("");
	const [restaurantTypeError, setRestaurantTypeError] = useState(false);
	
	const [instagram, setInstagram] = useState("");
	const instagramRef = useRef();
	
	const [contact, setContact] = useState("");
	const contactRef = useRef();
	const [contactError, setContactError] = useState(false);
	
	const [domain, setDomain] = useState([]);
	const [domainError, setDomainError] = useState(false);

	const [email, setEmail] = useState("");
	const emailRef = useRef();
	const [emailError, setEmailError] = useState(false);

	const [phone, setPhone] = useState("");
	const phoneRef = useRef();
	const [phoneError, setPhoneError] = useState(false);

	const [done, setDone] = useState(false);


	useEffect(() => {
		if (nameRef.current) {
			nameRef.current.focus();
		}
	}, [name]);


	useEffect(() => {
		if (instagramRef.current) {
			instagramRef.current.focus();
		}
	}, [instagram]);


	useEffect(() => {
		if (emailRef.current) {
			emailRef.current.focus();
		}
	}, [email]);


	useEffect(() => {
		if (contactRef.current) {
			contactRef.current.focus();
		}
	}, [contact]);


	useEffect(() => {
		if (phoneRef.current) {
			phoneRef.current.focus();
		}
	}, [phone]);


	const handleSubmit = () => {
		isValidText(name) === false ? setNameError(true) : setNameError(false);
		isValidText(contact) === false ? setContactError(true) : setContactError(false);
		isValidEmail(email) === false ? setEmailError(true) : setEmailError(false);
		isValidText(phone) === false ? setPhoneError(true) : setPhoneError(false);
		onlyNumbers(phone) === false ? setPhoneError(true) : setPhoneError(false);
		minPhoneLength(phone) === false ? setPhoneError(true) : setPhoneError(false);
		restaurantType.length === 0 ? setRestaurantTypeError(true) : setRestaurantTypeError(false);
		domain.length === 0 ? setDomainError(true) : setDomainError(false);
		if (checkForm()) {
			const data = {
				name: name,
				restaurantType: restaurantType,
				instagram: instagram,
				contact: contact,
				domain: domain,
				email: email,
				phone: phone
			};
			axios.post("/api/sendFirstContactForm/", data)
				.then(response => {
					if (response.data.success) {
						setDone(true);
					} else {
						console.log("Error sending form");
					}
				});
		} else {
			console.log("Form is invalid");
		}
	}


	const checkForm = () => {
		return isValidText(name) && isValidText(contact) && isValidEmail(email) && restaurantType.length > 0 && domain.length > 0 && isValidText(phone) && onlyNumbers(phone) && minPhoneLength(phone);
	}


	const isValidText = (text) => {
		return text.length > 0;
	}


	const onlyNumbers = (text) => {
		const numbers = /^[0-9]+$/;
		return numbers.test(text);
	}


	const minPhoneLength = (text) => {
		return text.length >= 9;
	}


	const isValidEmail = (email) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}


	const TucoTextInput = React.forwardRef(({ value, onValueChange, placeholder, error, errorMessage }, ref) => {
		return (
			<TextInput ref={ref} value={value} onValueChange={onValueChange} placeholder={placeholder} error={error} errorMessage={errorMessage} />
		);
	});


	return (
		<>
		{!done &&
		<Grid container spacing={1} className="flex justify-center h-screen items-center w-6/12">
			<Grid item xs={12} className="flex justify-center">
				<Card isBlurred className="w-8/12 max-h-screen overflow-y-auto">
					<CardBody>
						<Grid container className="flex justify-between items-center">
							<Grid item xs={12} className="flex justify-center items-center gap-4 py-4">
								<p className="tuco-title">Formulaire TUCO</p>
								<Tuco />
							</Grid>
							<Grid item xs={12} className="pb-2">
								<p className="tuco-text text-center">Accédez <span className="tuco-bold">gratuitement</span> à nos conseils en <span className="tuco-bold">communication marketing</span> pour les <span className="tuco-bold">restaurateurs.</span></p>
							</Grid>
							<Grid item xs={12} className="pb-4">
								<p className="tuco-text text-center">Nous sommes <span className="tuco-bold">convaincus</span> de pouvoir vous apporter <span className="tuco-bold">une réelle plus-value</span>, que ce soit pour <span className="tuco-bold">améliorer votre expérience client</span> ou augmenter <span className="tuco-bold">votre rentabilité.</span></p>
							</Grid>
							<Grid item xs={12} className="pb-2">
								<p className="tuco-bold text-center">Remplissez donc ce formulaire afin de travailler ensemble dans les meilleurs conditions.</p>
							</Grid>
							<Divider />
							<Grid item xs={1}>
							</Grid>
							<Grid item xs={10} className="pb-3">
								<p className="tuco-text text-left pl-2">NOM DE L'ENTREPRISE.</p>
								{nameError && <TucoTextInput ref={nameRef} value={name} onValueChange={(newValue) => setName(newValue)} error={true} errorMessage="Ne peut pas être vide" />}
								{!nameError && <TucoTextInput ref={nameRef} value={name} onValueChange={(newValue) => setName(newValue)} />}
							</Grid>
							<Grid item xs={1}>
							</Grid>
							<Grid item xs={1}>
							</Grid>
							<Grid item xs={10} className="pb-3 max-h-screen">
								<p className="tuco-text text-left pl-2">TYPE DE CUISINE/SERVICE :</p>
								{restaurantTypeError && <Select id="restaurantType" value={restaurantType} onChange={setRestaurantType} error={true} errorMessage="Sélectionnez un type" className="max-h-screen">
									<SelectItem value="classic">Classique</SelectItem>
									<SelectItem value="fastFood">Fast Food</SelectItem>
									<SelectItem value="monde">Cuisine du monde</SelectItem>
									<SelectItem value="tendance">Tendance</SelectItem>
									<SelectItem value="healthy">Healthy</SelectItem>
									<SelectItem value="bistronomique">Bistronomique</SelectItem>
									<SelectItem value="gastronomique">Gastronomique</SelectItem>
								</Select>}
								{!restaurantTypeError && <Select id="restaurantType" value={restaurantType} onChange={setRestaurantType} className="max-h-screen">
									<SelectItem value="classic">Classique</SelectItem>
									<SelectItem value="fastFood">Fast Food</SelectItem>
									<SelectItem value="monde">Cuisine du monde</SelectItem>
									<SelectItem value="tendance">Tendance</SelectItem>
									<SelectItem value="healthy">Healthy</SelectItem>
									<SelectItem value="bistronomique">Bistronomique</SelectItem>
									<SelectItem value="gastronomique">Gastronomique</SelectItem>
								</Select>}
							</Grid>
							<Grid item xs={1}>
							</Grid>
							<Grid item xs={1}>
							</Grid>
							<Grid item xs={10} className="pb-3">
								<p className="tuco-text text-left pl-2">INSTAGRAM.</p>
								<TucoTextInput ref={instagramRef} placeholder="@" value={instagram} onValueChange={setInstagram} />
							</Grid>
							<Grid item xs={1}>
							</Grid>
							<Grid item xs={1}>
							</Grid>
							<Grid item xs={10} className="pb-3">
								<p className="tuco-text text-left pl-2">NOM-PRÉNOM DU CONTACT.</p>
								{contactError && <TucoTextInput ref={contactRef} value={contact} onValueChange={setContact} error={true} errorMessage="Ne peut pas être vide" />}
								{!contactError && <TucoTextInput ref={contactRef} value={contact} onValueChange={setContact} />}
							</Grid>
							<Grid item xs={1}>
							</Grid>
							<Grid item xs={1}>
							</Grid>
							<Grid item xs={10} className="pb-3 max-h-screen">
								<p className="tuco-text text-left pl-2">SÉLECTIONNEZ LES DOMAINES QUI VOUS INTÉRESSENT POUR DES CONSEILS :</p>
								{domainError && <MultiSelect id="domain" value={domain} onValueChange={setDomain} error={true} errorMessage="Sélectionnez au moins un domaine" >
									<MultiSelectItem value="com">Communication Marketing</MultiSelectItem>
									<MultiSelectItem value="inno">Innovations / Tendances pour restaurants</MultiSelectItem>
									<MultiSelectItem value="upgrade">Amélioration de l'expérience client</MultiSelectItem>
									<MultiSelectItem value="opti">Optimisation du menu pour la vente</MultiSelectItem>		
								</MultiSelect>}
								{!domainError && <MultiSelect id="domain" value={domain} onValueChange={setDomain} >
									<MultiSelectItem value="com">Communication Marketing</MultiSelectItem>
									<MultiSelectItem value="inno">Innovations / Tendances pour restaurants</MultiSelectItem>
									<MultiSelectItem value="upgrade">Amélioration de l'expérience client</MultiSelectItem>
									<MultiSelectItem value="opti">Optimisation du menu pour la vente</MultiSelectItem>		
								</MultiSelect>}
							</Grid>
							<Grid item xs={1}>
							</Grid>
							<Grid item xs={1}>
							</Grid>
							<Grid item xs={10} className="pb-3">
								<p className="tuco-text text-left pl-2">EMAIL.</p>
								{emailError && <TucoTextInput ref={emailRef} value={email} onValueChange={setEmail} error={true} errorMessage="Email invalide" />}
								{!emailError && <TucoTextInput ref={emailRef} value={email} onValueChange={setEmail} />}
							</Grid>
							<Grid item xs={1}>
							</Grid>
							<Grid item xs={1}>
							</Grid>
							<Grid item xs={10} className="pb-3">
								<p className="tuco-text text-left pl-2">TÉLÉPHONE.</p>
								{phoneError && <TucoTextInput ref={phoneRef} value={phone} onValueChange={setPhone} error={true} errorMessage="Numéro invalide" />}
								{!phoneError && <TucoTextInput ref={phoneRef} value={phone} onValueChange={setPhone} />}
							</Grid>
							<Grid item xs={1}>
							</Grid>
							<Grid item xs={12} className="flex justify-center items-center py-2">
								<button className="btn-tuco w-36" onClick={handleSubmit}>Soumettre</button>
							</Grid>
						</Grid>
					</CardBody>
				</Card>
			</Grid>
		</Grid>
		}
		{done &&
			<Grid container spacing={1} className="flex justify-center h-screen items-center">
				<p className="tuco-title text-5xl animate-appearance-in ease-in-out">Formulaire envoyé, merci pour votre confiance !</p>
			</Grid>
		}
		</>
	);
}