import React from 'react';
import { Tuco } from './misc/Tuco';
import { useGlobal } from "./globalContext";

const PlatformBackground = ({ children, ref }) => {

	const { showAlert, alertMessage } = useGlobal();

	return (
			<div className="w-full h-screen bg-tremor-tuco-dark relative">
				<div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 h-fit w-fit blur z-10">
					<Tuco size='512px' />
				</div>
				<div ref={ref} className="fixed h-screen w-full z-20">
					{children}
				</div>
				{ (showAlert === "error" || showAlert === "success") && alertMessage !== "" &&
					<div className="fixed flex justify-center items-end w-full h-screen z-50">
						<div className={`fixed bottom-0 w-fit max-w-[60%] ${showAlert === "error" ? "bg-red-500" : "bg-green-500"} text-white rounded-3xl py-3 px-10`}>
							<p className="text-center">{alertMessage}</p>
						</div>
					</div>
				}
			</div>
		);
};

export default PlatformBackground;