import React, { useState, useEffect, useRef } from "react";
import{ useNavigate, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { Card, CardHeader, CardBody } from "@nextui-org/card";
import TucoTextInputBig from "../misc/TucoTextInputBig";
import axios from "axios";


export default function RestauForm3() {

	const navigate = useNavigate();
	const location = useLocation();
	const [restaurantName, setRestaurantName] = useState('');
	const [restaurantId, setRestaurantId] = useState('');
	const [styleSelected, setStyleSelected] = useState([]);
	const [isExiting, setIsExiting] = useState(false);
	const [firstStep, setFirstStep] = useState(false);
	const [adjectivesSelected, setAdjectivesSelected] = useState([]);
	const [concept, setConcept] = useState('');
	const [identity, setIdentity] = useState('');
	const [under750, setUnder750] = useState(window.innerHeight < 750);
	const [under690, setUnder690] = useState(window.innerHeight < 690);
	const [under640, setUnder640] = useState(window.innerHeight < 640);
	const [over830, setOver830] = useState(window.innerHeight > 830);


	useEffect(() => {
		function handleResize() {
			setUnder750(window.innerHeight < 750);
			setUnder690(window.innerHeight < 690);
			setUnder640(window.innerHeight < 640);
			setOver830(window.innerHeight > 830);
		}
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => {
			window.removeEventListener('resize', handleResize);
		}
	}, []);


	const circleSize = under750 ? "w-[75px] h-[75px]" : "w-[95px] h-[95px]";
    const emojiSize = under750 ? "w-12 h-12" : "w-16 h-16";
	let minRow1 = 3;
	let minRow2 = 3;
	if (under640) {
		minRow1 = 2;
		minRow2 = 2;
	} else if (under690) {
		minRow1 = 2;
		minRow2 = 3;
	} else if (under750) {
		minRow1 = 3;
		minRow2 = 3;
	} else if (over830) {
		minRow1 = 4;
		minRow2 = 4;
	}


	useEffect(() => {
		const query = new URLSearchParams(location.search);
		const restaurantName = query.get('restaurantName');
		const id = query.get('id');
		if (!restaurantName || !id) {
			navigate('/forms/restau/1');
		}
		setRestaurantName(decodeURIComponent(restaurantName));
		setRestaurantId(id);
		setStyleSelected([]);
		if (isExiting) {
				const encodedRestaurantName = encodeURIComponent(restaurantName);
				navigate(`/forms/restau/4?restaurantName=${encodedRestaurantName}&id=${restaurantId}`);
		  }
	}, [isExiting, location.search, navigate]);


	const handleClickAnimate = () => {
		const data = {
			restaurantName: restaurantName,
			restaurantId: restaurantId,
			style: styleSelected
		};
		axios.post('/api/restauForm/new/style/', data)
		.then(response => {
			if (response.data.success) {
				setFirstStep(true);
			} else {
				throw new Error('Une erreur inattendue est survenue. Veuillez réessayer plus tard.');
			}
		})
		.catch(error => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			alert(errorMessage);
		});
	}


	const handleClickNext = () => {
		const data = {
			restaurantName: restaurantName,
			restaurantId: restaurantId,
			adjectives: adjectivesSelected,
			concept: concept,
			identity: identity
		};
		axios.post('/api/restauForm/new/details/', data)
		.then(response => {
			if (response.data.success) {
				setIsExiting(true);
			} else {
				throw new Error('Une erreur inattendue est survenue. Veuillez réessayer plus tard.');
			}
		})
		.catch(error => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			alert(errorMessage);
		});
	}


	const handleStyleSelected = (e) => {
        const id = e.currentTarget.id;
        setStyleSelected(currentSelection => {
            if (currentSelection.includes(id)) {
                return currentSelection.filter(objective => objective !== id);
            }
            if (currentSelection.length < 1) {
                return [...currentSelection, id];
            }
            return currentSelection;
        });
    };


	const handleAdjectiveSelected = (e) => {
		const id = e.currentTarget.id;
		setAdjectivesSelected(currentSelection => {
			if (currentSelection.includes(id)) {
				return currentSelection.filter(adjective => adjective !== id);
			} else {
				return [...currentSelection, id];
			}
		});
	}


	const TucoAdjectives = ({ adjective, onClick }) => {
		return (
			<div id={adjective} className={`bg-tremor-tuco-latte rounded-full shadow-sm shadow-gray-500 ${adjectivesSelected.includes(adjective) ? "border-1 border-tremor-tuco-green" : null } `} style={{ width: 'fit-content' }} onClick={onClick} >
				<p className="tuco-text text-md tablet-min:text-2xl py-[1px] px-2">{adjective}</p>
			</div>
		);
	}


	return (
		<>
			{ !firstStep ? (
				<AnimatePresence>
				<motion.div id="bento" initial={{ opacity:0, scale:0.8 }} animate={{ opacity:1, scale:1 }} transition={{ duration: 1.2 }}
							exit={{ opacity:0, scale:0.5, transition: { duration: 0.5, delay: 0.2 } }}
							className={`grid grid-rows-12 grid-cols-24 gap-2 h-screen w-full justify-center items-center p-2 relative`}>
					<div className="row-span-3 col-span-24 w-full h-full pt-5 tablet-min:pt-16">
						<p className="tuco-title-white text-center mb-3">Quel est le type de votre établissement ?</p>
						<p className="tuco-text-white text-center">Sélectionnez celui qui vous correspond à travers ces images.</p>
					</div>
					<div className={`row-span-4 col-span-12 flex flex-col justify-between items-center w-full h-full bg-tremor-tuco-beige ${ styleSelected.includes("bistro") ? ('box-selected') : ('box-2') }`} id="bistro" onClick={handleStyleSelected}>
						<p className="tuco-undertitle text-sm phone-lg:text-xl text-center pt-1 bg-tremor-tuco-latte w-full">Bistro - Café</p>
						<div className="flex justify-center items-center w-full h-full">
							<img className="max-h-[85%] rounded-xl p-1" src="/imgs/icons/bistro.png" alt="bistro" />
						</div>
					</div>
					<div className={`row-span-4 col-span-12 flex flex-col justify-between items-center w-full h-full bg-tremor-tuco-beige ${ styleSelected.includes("high") ? ('box-selected') : ('box-2') }`} id="high" onClick={handleStyleSelected}>
						<p className="tuco-undertitle text-sm phone-lg:text-xl text-center pt-1 bg-tremor-tuco-latte w-full">Haut de gamme</p>
						<div className="flex justify-center items-center w-full h-full">
							<img className="max-h-[85%] rounded-xl p-1" src="/imgs/icons/high.png" alt="high" />
						</div>
					</div>
					<div className={`row-span-4 col-span-12 flex flex-col justify-between items-center w-full h-full bg-tremor-tuco-beige ${ styleSelected.includes("fastfood") ? ('box-selected') : ('box-2') }`} id="fastfood" onClick={handleStyleSelected}>
						<p className="tuco-undertitle text-sm phone-lg:text-xl text-center pt-1 bg-tremor-tuco-latte w-full">Fast Food</p>
						<div className="flex justify-center items-center w-full h-full">
							<img className="max-h-[85%] rounded-xl p-1" src="/imgs/icons/fast-food.png" alt="fastfood" />
						</div>
					</div>
					<div className={`row-span-4 col-span-12 flex flex-col justify-between items-center w-full h-full bg-tremor-tuco-beige ${ styleSelected.includes("world") ? ('box-selected') : ('box-2') }`} id="world" onClick={handleStyleSelected}>
						<p className="tuco-undertitle text-sm phone-lg:text-xl text-center pt-1 bg-tremor-tuco-latte w-full">Cuisine du monde</p>
						<div className="flex justify-center items-center w-full h-full">
							<img className="max-h-[85%] rounded-xl p-1" src="/imgs/icons/world.png" alt="world" />
						</div>
					</div>
					{ styleSelected.length > 0 &&
					<div className="row-span-1 col-start-16 col-span-8 flex justify-end items-end">
						<button className="btn-tuco-uh w-full mt-2 text-black shadow-lg" onClick={handleClickAnimate}>Continuer</button>
					</div>}
				</motion.div></AnimatePresence>) :
				<motion.div id="details" initial={{ opacity:0, scale:0.8 }} animate={{ opacity:1, scale:1 }} transition={{ duration: 1.2 }}
				exit={{ opacity:0, scale:0.5, transition: { duration: 0.5, delay: 0.2 } }}
				className={`grid grid-rows-16 grid-cols-24 gap-2 h-full w-full justify-center items-center p-2 relative max-h-screen`}>
					<div className="row-span-2 col-span-24 w-full h-full pt-5">
						<p className="tuco-title-white text-center p-1">Derniers détails !</p>
					</div>
					<div className="row-span-6 col-span-24 flex flex-col justify-center h-full items-center">
						<p className="tuco-text-white text-center">Sélectionnez les adjectifs les plus pertinents pour votre établissement.</p>
						<Card className="w-[98%] h-[80%] p-2 overflow-y-scroll bg-white/95">
							<CardBody className="flex flex-row flex-wrap items-start gap-2 justify-center">
								<TucoAdjectives adjective="Accueillant" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Convivial" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Chaleureux" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Épuré" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Romantique" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Familial" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Festif" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Décontracté" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Chic" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Coloré" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Zen" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Élégant" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Raffiné" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Luxueux" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Moderne" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Rustique" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Tendance" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Traditionnel" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Thématique" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Minimaliste" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Artistique" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Vintage" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Original" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Gastronomique" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Paisible" onClick={handleAdjectiveSelected} />
								<TucoAdjectives adjective="Naturel" onClick={handleAdjectiveSelected} />
							</CardBody>
						</Card>
					</div>
					<div className="row-span-3 col-span-24">
						<TucoTextInputBig startText="Décrivez le concept de votre restaurant." value={concept} onValueChange={setConcept} placeholder="Pour que votre menu s'adapte parfaitement !" minRows={minRow1} />
					</div>
					<div className="row-span-3 col-span-24">
						<TucoTextInputBig startText="Quelles images voulaient vous que le client garde en sortant de votre établissement." value={identity} onValueChange={setIdentity} placeholder="Afin de proposer quelque chose en adéquation avec votre vision." minRows={minRow2} />
					</div>
					{ adjectivesSelected.length > 0 &&
					<div className="row-span-2 col-start-16 col-span-8 flex justify-end items-end z-10">
						<button className="btn-tuco-uh w-full mt-2 text-black shadow-lg" onClick={handleClickNext}>Continuer</button>
					</div>}
				</motion.div>}
			<AnimatePresence>
				{ !firstStep ? (
				<motion.div key="palette" initial={{ opacity:0, rotate:90 }} animate={under750 ? { opacity:1, x:'-35px', rotate:0 } : { opacity:1, x:'-50px', rotate:0 }}  transition={{ duration: 1.2, delay: 0.3 }}
					exit={under750 ? { opacity:0, y:'35%', x:'-100%', rotate:-90, transition: { duration: 1 } } : { opacity:0, y:'50%', x:'-100%', rotate:-90, transition: { duration: 1 } }}
					className="fixed bottom-9 left-1/2 transform">
						<div className={`flex justify-center items-center rounded-full bg-tremor-tuco-dark p-2 ${circleSize} shadow-lg shadow-gray-500 border-1 border-tremor-tuco-latte`}>
							<img src='/imgs/emojis/palette.png' alt="palette" className={emojiSize} />
						</div>
				</motion.div>) : !isExiting &&
				<motion.div key="bic" initial={{ opacity:0, rotate:90 }} animate={under750 ? { opacity:1, x:'-35px', rotate:0 } : { opacity:1, x:'-50px', rotate:0 }}  transition={{ duration: 1.2, delay: 0.8 }}
					exit={under750 ? { opacity:0, y:'35%', x:'-100%', rotate:-90, transition: { duration: 1 } } : { opacity:0, y:'50%', x:'-100%', rotate:-90, transition: { duration: 1 } }}
					className="fixed bottom-9 left-1/2 transform">
						<div className={`flex justify-center items-center rounded-full bg-tremor-tuco-dark p-2 ${circleSize} shadow-lg shadow-gray-500 border-1 border-tremor-tuco-latte`}>
							<img src='/imgs/emojis/bic.png' alt="bic" className={emojiSize} />
						</div>
				</motion.div>}
			</AnimatePresence>
		</>
	);
}