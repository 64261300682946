import React from "react";
import { Tuco } from "../misc/Tuco";

export default function Landing() {
	return (
		<>
			<div className="flex flex-col h-screen w-full">
				<div className="flex grow-0 justify-center items-start">
					<Tuco />
				</div>
				<div className="flex grow justify-center items-center">
					<p className="tuco-big-title-white text-center">
						<span className="tuco-title-animate title-word-1">Bienvenue </span>
						<span className="tuco-title-animate title-word-2">chez </span>
						<span className="tuco-title-animate title-word-3">TUCO</span>
					</p>
				</div>
			</div>
		</>
	);
}