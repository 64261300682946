import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import RestauForm1 from './forms/RestauForm-1';
import RestauForm2 from './forms/RestauForm-2';
import RestauForm3 from './forms/RestauForm-3';
import RestauForm4 from './forms/RestauForm-4';
import FormDone from './forms/FormDone';
import FormDetails from './formDetails';
import Confidentiality from './website/Confidentiality';


const FormRoutes = () => {
	const location = useLocation();

	return (
		<AnimatePresence mode="sync">
			<Routes location={location} key={location.pathname}>
				<Route path="/" element={<RestauForm1 />} />
				<Route path="/forms/restau/2" element={<RestauForm2 />} />
				<Route path="/forms/restau/3" element={<RestauForm3 />} />
				<Route path="/forms/restau/4" element={<RestauForm4 />} />
				<Route path="/forms/done" element={<FormDone />} />
				<Route path="/forms/details" element={<FormDetails />} />
				<Route path="/website/confidentiality" element={<Confidentiality />} />
			</Routes>
		</AnimatePresence>
	);
}

export default FormRoutes;