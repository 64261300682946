import React, { useEffect, useRef } from "react";
import { Textarea } from "@nextui-org/input";


export default function TucoTextInputBig({ startText, value, onValueChange, placeholder, className, minRows }) {
	const textAreaRef = useRef(null);


	useEffect(() => {
		if (textAreaRef.current) {
			textAreaRef.current.focus();
		}
	}, []);


	return (
	<div className={`flex flex-col pt-4 p-2 ${className}`}>
		<p className="tuco-text-white text-center">{startText}</p>
		<Textarea
		ref={textAreaRef}
		className="mt-2"
		value={value}
		onChange={(e) => onValueChange(e.target.value)}
		placeholder={placeholder}
		minRows={minRows}
		/>
	</div>
	);
};