import React, { useState, useRef, useEffect } from "react";
import{ useNavigate } from "react-router-dom";
import { Tuco } from "../misc/Tuco";
import { motion, AnimatePresence } from "framer-motion";
import { TextInput } from "@tremor/react";
import axios from "axios";

export default function RestauForm1() {

	const [restaurantName, setRestaurantName] = useState('');
	const [restaurantNameError, setRestaurantNameError] = useState(false);
	const restaurantNameRef = useRef();
	const [isMounted, setIsMounted] = useState(true);
	const navigate = useNavigate();
	const [under750, setUnder750] = useState(window.innerHeight < 750);


	useEffect(() => {
		function handleResize() {
			setUnder750(window.innerHeight < 750);
		}
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => {
			window.removeEventListener('resize', handleResize);
		}
	}, []);


	const circleSize = under750 ? "w-[75px] h-[75px]" : "w-[95px] h-[95px]";
    const emojiSize = under750 ? "w-12 h-12" : "w-16 h-16";


	useEffect(() => {
		if (restaurantNameRef.current) {
			restaurantNameRef.current.focus();
		}
		const timer = setTimeout(() => {
			setIsMounted(false);
		}, 2000);

		return () => clearTimeout(timer);
	}, [restaurantName]);


	const handleClickAnimate = () => {
		if (!restaurantName) {
			setRestaurantNameError(true);
			return;
		}
		setRestaurantNameError(false);
		axios.post('/api/restauForm/new/', { restaurantName: restaurantName })
		.then(response => {
			if (response.data.success) {
				const { restaurantName, id } = response.data;
				const encodedRestaurantName = encodeURIComponent(restaurantName);
				navigate(`/forms/restau/2?restaurantName=${encodedRestaurantName}&id=${id}`);
			} else {
				throw new Error('Une erreur inattendue est survenue. Veuillez réessayer plus tard.');
			}
		})
		.catch(error => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			alert(errorMessage);
		});
	}


	const TucoTextInput = React.forwardRef(({ value, onValueChange, placeholder, error, errorMessage, className }, ref) => {
		return (
			<TextInput minRows={1} className={className} ref={ref} value={value} onValueChange={onValueChange} placeholder={placeholder} error={error} errorMessage={errorMessage} />
		);
	});


	return (
		<>
			<AnimatePresence>
			{isMounted && <>
				<motion.div initial={{ opacity:0, scale:0.8 }} animate={{ opacity:1, scale:1 }} transition={{ duration: 1.2 }}
				exit={{ opacity:0, transition: { duration: 0.5 } }}
				className="absolute top-[40%] w-full justify-center">
					<p className="tuco-big-title-white text-center">
						<span className="tuco-title-animate title-word-1">Bienvenue </span>
						<span className="tuco-title-animate title-word-2">chez </span>
						<span className="tuco-title-animate title-word-3">TUCO</span>
					</p>
				</motion.div>
				<motion.div initial={{ opacity:0, x:'-50%' }} animate={{ opacity:1 }} transition={{ duration: 0.8 }}
				exit={under750 ? { opacity:0, y:'35%', x:'-100%', rotate:-90, transition: { duration: 1 } } : { opacity:0, y:'50%', x:'-100%', rotate:-90, transition: { duration: 1 } }} 
				className="fixed bottom-4 left-1/2 transform">
					<Tuco size="120px" />
				</motion.div>
			</>}
			</AnimatePresence>
			{!isMounted && <>
				<motion.div initial={{ opacity:0 }} animate={{ opacity:1 }} transition={{ duration: 1.2, delay: 0.5}}
				exit={{ opacity:0, transition: { duration: 0.5 }}}
				className="absolute top-[40%] w-full justify-center">
					<p className="tuco-title-white text-center">Quel est le nom de votre restaurant ?</p>
					<div className="flex flex-col w-full justify-center items-center mt-2">
						<TucoTextInput ref={restaurantNameRef} value={restaurantName} onValueChange={setRestaurantName} placeholder="Nom du restaurant" className="w-10/12 md:w-8/12 mt-2" error={restaurantNameError} errorMessage="Un menu, c'est mieux avec votre nom." />
						<button className="btn-tuco-uh w-4/12 md:w-8/12 mt-2 text-black shadow-lg" onClick={handleClickAnimate}>Continuer</button>
					</div>
				</motion.div>
				<motion.div initial={{ opacity:0, rotate:90 }} animate={under750 ? { opacity:1, x:'-35%', rotate:0 } : { opacity:1, x:'-50%', rotate:0 }} transition={{ duration: 1.2, delay: 0.5}}
				className="fixed bottom-9 left-1/2 transform"
				exit={under750 ? { opacity:0, y:'35%', x:'-100%', rotate:-90, transition: { duration: 1 } } : { opacity:0, y:'50%', x:'-100%', rotate:-90, transition: { duration: 1 } }} >
					<div className={`flex justify-center items-center rounded-full bg-tremor-tuco-dark p-2 ${circleSize} shadow-lg shadow-gray-500 border-1 border-tremor-tuco-latte`}>
						<img src='/imgs/emojis/smiling.png' alt="smiling face" className={emojiSize} />
					</div>
				</motion.div>
			</>}
		</>
	);
}