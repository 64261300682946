import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import _ from "lodash"; // Importer Lodash

import { Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow, Card, TextInput } from "@tremor/react";
import { Dialog, DialogPanel, Button } from "@tremor/react";
import { RiArrowUpSFill, RiArrowDownSFill, RiSearchLine, RiAtLine, RiCheckboxIndeterminateLine, RiStarSmileFill, RiSendPlaneFill, RiArrowRightLine, RiArrowLeftLine } from "@remixicon/react";
import { Grid } from "@mui/material";
import { Listbox, ListboxItem } from "@nextui-org/listbox";
import { ListboxWrapper } from "./misc/ListboxWrapper";
import { TucoLogo } from "./misc/TucoLogoSpin";

import { Link } from "react-router-dom";

export default function Databases() {

	// DB data 
	const [instagramData, setInstagramData] = useState([]);
	const [instagramRestaurantsCount, setInstagramRestaurantsCount] = useState(0);
	const [followersSortOrder, setFollowersSortOrder] = useState("asc");
	const [followsSortOrder, setFollowsSortOrder] = useState("asc");
	const [searchUsername, setSearchUsername] = useState("");
	const [filteredInstagramData, setFilteredInstagramData] = useState([]);
	const [dbLoading, setDbLoading] = useState(false);
	
	//Card data
	const [pageNumber, setPageNumber] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(6);
	const [cardHeight, setCardHeight] = useState("780px");
	const [maxPages, setMaxPages] = useState(0);


	// Google search model and data
	const [googleDialogOpen, setGoogleDialogOpen] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [googleLoading, setGoogleLoading] = useState(false);
	const [googleFoundResults, setGoogleFoundResults] = useState([]);
	const [googleNewResults, setGoogleNewResults] = useState([]);
	const [googleDone, setGoogleDone] = useState(false);

	// Instagram manual
	const [instagramManualDialogOpen, setInstagramManualDialogOpen] = useState(false);
	const [instagramManualUsername, setInstagramManualUsername] = useState("");
	const [instagramManualLoading, setInstagramManualLoading] = useState(false);
	const [instagramManualNewResults, setInstagramManualNewResults] = useState({});
	const [instagramManualDone, setInstagramManualDone] = useState(false);

	// Right click menu
	const [rightClickMenuOpen, setRightClickMenuOpen] = useState(false);
	const [rightClickMenuPosition, setRightClickMenuPosition] = useState({x: 0, y: 0});
	const [activeItemIndex, setActiveItemIndex] = useState(null);
	const rightClickMenuRef = useRef(null);

	const debouncedSearch = useCallback(
		_.debounce((searchText) => {
			setPageNumber(1);
			getInstagramData(pageNumber, itemsPerPage, searchText);
		}, 500),
		[pageNumber, itemsPerPage] // Dépendances de useCallback
	);

	useEffect(() => {
		const adjustCardSize = () => {
			if (window.innerHeight > 935) {
				setItemsPerPage(7);
				setCardHeight("860px");
			} else {
				setItemsPerPage(6);
				setCardHeight("780px");
			}
		};
		adjustCardSize();

		window.addEventListener('click', handleClickOutside);
		window.addEventListener('resize', adjustCardSize);
		getInstagramData(pageNumber, itemsPerPage, searchUsername);
		return () => {
			window.removeEventListener('click', handleClickOutside);
			window.removeEventListener('resize', adjustCardSize);
			debouncedSearch.cancel();
		};
	}, [debouncedSearch]);

	const handleClickOutside = (event) => {
        if (rightClickMenuRef.current && !rightClickMenuRef.current.contains(event.target)) {
            setRightClickMenuOpen(false);
        }
    };


	const handleFollowersSort = () => {
		const newOrder = followersSortOrder === "desc" ? "asc" : "desc";
		setFollowersSortOrder(newOrder);
		setPageNumber(1);
		getInstagramDataFollowers(pageNumber, itemsPerPage, newOrder);
	};


	const handleFollowsSort = () => {
		const newOrder = followsSortOrder === "desc" ? "asc" : "desc";
		setFollowsSortOrder(newOrder);
		setPageNumber(1);
		getInstagramDataFollows(pageNumber, itemsPerPage, newOrder);
	};


	const handlePageChange = (change) => {
		setPageNumber(prevPageNumber => {
			// Calcule la nouvelle valeur de pageNumber basée sur l'action du bouton
			const updatedPageNumber = change === 'next' ? prevPageNumber + 1 : prevPageNumber - 1;
	
			// Appelle getInstagramData avec la nouvelle valeur de pageNumber
			getInstagramData(updatedPageNumber, itemsPerPage);
	
			// Retourne la nouvelle valeur pour mettre à jour l'état
			return updatedPageNumber;
		});
	};


	const applyFilter = (searchText, data) => {
		const filteredData = data.filter(item => item.username.toLowerCase().includes(searchText.toLowerCase()));
		setFilteredInstagramData(filteredData);
	};


	const handleSearchUsernameChange = (e) => {
		const text = e.target.value;
		setSearchUsername(text);
		debouncedSearch(text);
	};


	const handleSearchQueryChange = (e) => {
		setSearchQuery(e.target.value);
	};


	const handleInstagramManualUsernameChange = (e) => {
		setInstagramManualUsername(e.target.value);
	};


	const handleStatusClick = (username, status) => {
		if (status === "contacted") {
			return;
		}
		const data = {
			username: username
		};
		axios.post("/api/updateStatus/", data)
			.then(response => {
				const data = response.data;
				if (data.success) {
					const updatedData = instagramData.map(item => {
						if (item.username === username) {
							return {...item, status: response.data.status};
						}
						return item;
					});
					setInstagramData(updatedData);
					applyFilter(searchUsername, updatedData);
				} else {
					console.log(data.error);
				}
			});
	};


	const handleGoogleSearch = () => {
		const data = {
			query: searchQuery
		};
		setGoogleLoading(true);
		axios.post("/api/queryGoogle/", data)
			.then(response => {
				const data = response.data;
				if (data.success) {
					getInstagramData();
					setGoogleLoading(false);
					setGoogleDone(true);
					setGoogleFoundResults(data.restaurantNames);
					setGoogleNewResults(data.newRestaurantNames);
				}
		})
	};


	const handleInstagramSearch = () => {
		const data = {
			username: instagramManualUsername
		};
		setInstagramManualLoading(true);
		axios.post("/api/queryInstagram/", data)
			.then(response => {
				const data = response.data;
				console.log(data);
				setInstagramManualLoading(false);
				setInstagramManualNewResults(data);
				setInstagramManualDone(true);
			});
	};


	const handleDeleteRestaurant = (username) => {
		axios.delete(`/api/deleteRestaurant/${username}`)
			.then(response => {
				const data = response.data;
				if (data.success) {
					getInstagramData();
				} else {
					console.log(data.error);
				}
			});
	};


	const handleUpdateRestaurant = (username) => {
		axios.post(`/api/updateRestaurant/${username}`)
			.then(response => {
				const data = response.data;
				if (data.success) {
					getInstagramData();
				} else {
					console.log(data.error);
				}
			});
	};


	const handleContextMenu = (e, index) => {
		e.preventDefault();
	
		setRightClickMenuOpen(true);
    	setRightClickMenuPosition({x: e.pageX, y: e.pageY});
		setActiveItemIndex(index); // Mise à jour de l'élément actif
	};


	const getInstagramData = async (pageNumber, itemsPerPage, searchUsername = '') => {
		setDbLoading(true);
		try {
			const url = `/api/getInstagramDB/${pageNumber}/${itemsPerPage}?username=${encodeURIComponent(searchUsername)}`;
			const response = await axios.get(url);
			const data = response.data;
				if (data.restaurants) {
					setInstagramData(data.restaurants);
					setFilteredInstagramData(data.restaurants);
					setInstagramRestaurantsCount(data.totalEntries);
					setMaxPages(data.totalPages);
					setDbLoading(false);
				}
			} catch (error) {
				console.error("Failed to fetch data:", error);
				setDbLoading(false);
			}
	};


	const getInstagramDataFollowers = async (pageNumber, itemsPerPage, followersSortOrder = "asc") => {
		setDbLoading(true);
		try {
			const url = `/api/getInstagramDB/${pageNumber}/${itemsPerPage}?followersSortOrder=${followersSortOrder}`;
			const response = await axios.get(url);
			const data = response.data;
				if (data.restaurants) {
					setInstagramData(data.restaurants);
					setFilteredInstagramData(data.restaurants);
					setInstagramRestaurantsCount(data.totalEntries);
					setMaxPages(data.totalPages);
					setDbLoading(false);
				}
			} catch (error) {
				console.error("Failed to fetch data:", error);
				setDbLoading(false);
			}
	};


	const getInstagramDataFollows = async (pageNumber, itemsPerPage, followsSortOrder = "asc") => {
		setDbLoading(true);
		try {
			const url = `/api/getInstagramDB/${pageNumber}/${itemsPerPage}?followsSortOrder=${followsSortOrder}`;
			const response = await axios.get(url);
			const data = response.data;
				if (data.restaurants) {
					setInstagramData(data.restaurants);
					setFilteredInstagramData(data.restaurants);
					setInstagramRestaurantsCount(data.totalEntries);
					setMaxPages(data.totalPages);
					setDbLoading(false);
				}
			} catch (error) {
				console.error("Failed to fetch data:", error);
				setDbLoading(false);
			}
	};


	function TableItem ({id, profilePic, username, followers, follows, instagram, status, index, handleContextMenu}) {
		const menuId = `rightClickMenu-${index}`;
		return (
			<TableRow id={index} key={index} className="w-full">
				<TableCell onClick={() => handleStatusClick(username, status)}>
					{
						status === "none" ? (
							<RiCheckboxIndeterminateLine />
						) : status === "interesting" ? (
							<RiStarSmileFill className="text-tremor-tuco-mustard" />
						) : status === "contacted" ? (
							<RiSendPlaneFill className="text-tremor-tuco-green" />
						) : null
					}
				</TableCell>
				<TableCell >
					<div className="relative left-6">
						<img src={profilePic} className="rounded-full" alt="profile" style={{ width: '48px', height: '48px' }} />
					</div>
				</TableCell>
				<TableCell className="flex flex-row">
					<div className="mt-4">
						@{username}
					</div>
					<div ref={rightClickMenuRef} className="w-48 bg-black bg-opacity-90 rounded-md border-white"
						style={{ display: rightClickMenuOpen && activeItemIndex === index ? 'block' : 'none', position: 'fixed', left: rightClickMenuPosition.x, top: rightClickMenuPosition.y, zIndex: 1000 }}>
						<ListboxWrapper>
							<Listbox aria-label="Actions">
								<ListboxItem key={`new-${index}`} className="hover:bg-tremor-gab-light" textValue={`${username}`}>
									<Link to={`/database/${id}`}>{username}</Link>
								</ListboxItem>
								<ListboxItem key={`update-${index}`} className="text-success hover:bg-tremor-gab-light hover:text-green-600 hover:font-extrabold" color="success" onClick={() => handleUpdateRestaurant(username)}>Force update</ListboxItem>
								<ListboxItem key={`delete-${index}`} className="text-danger hover:bg-tremor-gab-light hover:text-red-600 hover:font-extrabold" color="danger" onClick={() => handleDeleteRestaurant(username)}>Delete entry</ListboxItem>
							</Listbox>
						</ListboxWrapper>
					</div>
					<div onContextMenu={(e) => handleContextMenu(e, index)} className="absolute" style={{ width: '54%', height: '11.1%', marginTop: '-15px' }} id={menuId} ></div>
				</TableCell>
				<TableCell className="text-right">{followers}</TableCell>
				<TableCell className="text-right">{follows}</TableCell>
				<TableCell>
					<div>
						<a href={instagram} target="_blank" rel="noopener noreferrer" className="text-tremor-gab">Go to Instagram</a>
					</div>
				</TableCell>
			</TableRow>
		);
	}


	function TableHeaderItem ({handle, sort, placeholder, readOnly}) {
		return (
			<TableHeaderCell onClick={handle} className="max-w-44">
				<TextInput icon={sort === "asc" ? RiArrowUpSFill : RiArrowDownSFill}
					placeholder={placeholder} readOnly={readOnly}/>
			</TableHeaderCell>
		);
	}


	return (
		<>
			<div className="flex flex-col w-full justify-center pt-4">
				<h1 className='text-4xl font-bold'>Welcome to<br/><span className='italic'>Tuco Databases</span></h1>
				<div className="flex justify-center items-center pt-4">
					<hr className="w-2/12 border-tremor-gab-dark" />
				</div>
			</div>
			<div className="flex w-11/12 justify-center items-center pt-6 ml-12">
				<Card className="relative w-full justify-center overflow-auto border-tremor-tuco-talon"
					decoration="left" style={{ height: cardHeight }}>
					<h3 className="text-left font-extrabold mb-2">Instagram database</h3>
					<Grid container spacing={2} className="flex justify-center">
						<Grid item xs={4}>
							<Card className="mx-auto max-w-xs my-2 cursor-pointer border-tremor-tuco-green" decoration="top" onClick={() => setGoogleDialogOpen(true)}>
								<p className="text-tremor-default text-tremor-content">Add Instagram</p>
								<p className="text-3xl text-tremor-content-strong font-semibold">Google search</p>
							</Card>
						</Grid>
						<Grid item xs={4}>
							<Card className="mx-auto max-w-xs my-2 cursor-pointer border-tremor-tuco-green" decoration="top" onClick={() => setInstagramManualDialogOpen(true)}>
								<p className="text-tremor-default text-tremor-content">Add Instagram</p>
								<p className="text-3xl text-tremor-content-strong font-semibold">Manually</p>
							</Card>
						</Grid>
						<Grid item xs={4}>
							<Card className="mx-auto max-w-xs my-2 border-tremor-tuco-green" decoration="top">
								<p className="text-tremor-default text-tremor-content">Total objects in database :</p>
								<p className="text-3xl text-tremor-content-strong font-semibold">{instagramRestaurantsCount}</p>
							</Card>
						</Grid>
					</Grid>
					{ dbLoading === false ? (
						<div className="w-full">
							<Table>
								<TableHead>
									<TableRow>
										<TableHeaderCell><RiCheckboxIndeterminateLine /></TableHeaderCell>
										<TableHeaderCell>Profile Picture</TableHeaderCell>
										<TableHeaderCell><TextInput icon={RiAtLine} placeholder="Username" value={searchUsername} onChange={handleSearchUsernameChange}/></TableHeaderCell>
										<TableHeaderItem handle={handleFollowersSort} sort={followersSortOrder} placeholder="Followers" readOnly />
										<TableHeaderItem handle={handleFollowsSort} sort={followsSortOrder} placeholder="Follows" readOnly />
										<TableHeaderCell>Instagram</TableHeaderCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{instagramData.map((item, index) => (
										<TableItem
											key={item.instagramId}
											id={item.instagramId}
											profilePic={item.profilePictureUrl}
											username={item.username}
											followers={item.followersCount}
											follows={item.followsCount}
											instagram={item.instagramLink}
											status={item.status}
											index={index}
											handleContextMenu={(e) => handleContextMenu(e, index)}
										/>
									))}
								</TableBody>
							</Table>
							<div className="flex justify-between">
								<span className="font-semi-bold">Page {pageNumber}</span>
								<div className="flex justify-between">
									{ pageNumber > 1 ? (
										<Button icon={RiArrowLeftLine} iconPosition="left" variant="light" color="green" className="mr-2" onClick={() => handlePageChange('prev')}>
											Previous
										</Button>
									) : null }
									{ pageNumber < maxPages ? (
										<Button icon={RiArrowRightLine} iconPosition="right" variant="light" color="green" className="ml-2" onClick={() => handlePageChange('next')}>
											Next
										</Button>
									) : null }
								</div>
							</div>
						</div>
					) : (
						<div className="flex justify-center items-center w-full h-48">
							<TucoLogo />
						</div>
					)}
				</Card>
				<Dialog open={googleDialogOpen} onClose={(val) => setGoogleDialogOpen(val)} static={true}>
					<DialogPanel>
						<h3 className="text-lg font-semibold text-tremor-content-strong-strong">Google search</h3>
						<TextInput className="mt-2" icon={RiSearchLine} placeholder="Search..." value={searchQuery} onChange={handleSearchQueryChange}/>
						{ googleLoading === false ?
							<Button className="mt-4 w-full bg-tremor-gab border-tremor-gab-white hover:bg-tremor-gab-dark hover:border-tremor-gab-white" onClick={handleGoogleSearch}>
								Search
							</Button>
						:
							<Button disabled className="mt-4 w-full bg-tremor-gab border-tremor-gab-white hover:bg-tremor-gab-dark hover:border-tremor-gab-white" onClick={handleGoogleSearch}>
								Loading ...
							</Button>
						}
						{ googleDone === true ?
							<Grid container spacing={2} className="flex justify-center">
								<Grid item xs={6}>
									<Card className="mx-auto max-w-xs my-2" decoration="top" decorationColor="green">
										<p className="text-tremor-default text-tremor-content">Found restaurants :</p><br/>
										{googleFoundResults.map((item, index) => (
											<p key={index} className="text-tremor-content-strong font-semibold">{item}</p>
										))}
									</Card>
								</Grid>
								<Grid item xs={6}>
									<Card className="mx-auto max-w-xs my-2" decoration="top" decorationColor="green">
										<p className="text-tremor-default text-tremor-content">New restaurants :</p><br/>
										{googleNewResults.map((item, index) => (
											<p key={index} className="text-tremor-gab font-semibold">{item}</p>
										))}
									</Card>
								</Grid>
							</Grid>
						:
							null
						}
					</DialogPanel>
				</Dialog>
				<Dialog open={instagramManualDialogOpen} onClose={(val) => setInstagramManualDialogOpen(val)} static={true}>
					<DialogPanel>
						<h3 className="text-lg font-semibold text-tremor-content-strong-strong">Manual search</h3>
						<TextInput className="mt-2" icon={RiAtLine} placeholder="Search..." value={instagramManualUsername} onChange={handleInstagramManualUsernameChange}/>
						{ instagramManualLoading === false ?
							<Button className="mt-4 w-full bg-tremor-gab border-tremor-gab-white hover:bg-tremor-gab-dark hover:border-tremor-gab-white" onClick={handleInstagramSearch}>
								Search
							</Button>
						:
							<Button disabled className="mt-4 w-full bg-tremor-gab border-tremor-gab-white hover:bg-tremor-gab-dark hover:border-tremor-gab-white" onClick={handleInstagramSearch}>
								Loading ...
							</Button>
						}
						{ instagramManualDone === true ?
							<Card className="mx-auto max-w-xs my-2" decoration="top" decorationColor="green">
								<Grid container spacing={2} className="flex justify-center items-center">
									<Grid item xs={4}>
										<img src={instagramManualNewResults.profilePictureUrl} alt="Profile Picture" className="rounded-full" style={{ width: '72px', height: '72px' }} />
									</Grid>
									<Grid item xs={8}>
										<div>
											<p className="text-right text-tremor-default text-tremor-content">New restaurant:</p>
											<p className="text-right text-tremor-gab font-semibold">{instagramManualNewResults.username}</p><br/>
											<p className="text-right text-tremor-content-strong font-semibold">{instagramManualNewResults.followersCount} followers</p>
											<p className="text-right text-tremor-content-strong font-semibold">{instagramManualNewResults.followsCount} follows</p><br/>
										</div>
									</Grid>
									<Grid item xs={12}>
										<p className="text-tremor-gab font-semibold text-center mb-2">Biography: </p>
										<p className="text-tremor-content-strong font-semibold text-center">{instagramManualNewResults.biography}</p>
									</Grid>
								</Grid>
							</Card>
						:
							null
						}
					</DialogPanel>
				</Dialog>
			</div>
		</>
	);
}