import React, {useState, useEffect, useCallback } from "react";
import axios from "axios";
import{ Link, useParams} from "react-router-dom";

import StateButtons from "./databaseDetail/stateButtons";

import { Card, TextInput } from "@tremor/react";
import { Dialog, DialogPanel, Button } from "@tremor/react";
import { RiArrowUpSFill, RiArrowDownSFill, RiSearchLine, RiCheckLine } from "@remixicon/react";
import { Grid, ImageList, ImageListItem } from "@mui/material";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownSection, DropdownItem } from "@nextui-org/dropdown";
import { Button as NextUIButton } from "@nextui-org/button";
import EmptyTextarea from "./misc/Textarea";
import { TucoLogo } from "./misc/TucoLogoSpin";
import { set } from "lodash";

export default function DatabaseDetail() {
	const { id } = useParams();
	const [dbLoading, setDbLoading] = useState(false);

	const [restaurant, setRestaurant] = useState({});
	const [notes, setNotes] = useState("");
	const [images, setImages] = useState([]);
	const [imageDialogOpen, setImageDialogOpen] = useState(false);
	const [selectedState, setSelectedState] = useState("none");

	useEffect(() => {
		getDatabase();
		const handleWheel = (e) => {
			e.preventDefault();
			const container = document.querySelector('.tucontainer');
			if (container) {
				container.scrollLeft += e.deltaY;
			}
		};
		const container = document.querySelector('.tucontainer');
		if (container) {
			container.addEventListener('wheel', handleWheel, { passive: false });
		}
		return () => {
			if (container) {
				container.removeEventListener('wheel', handleWheel);
			}
		};
	}, [id]);


	const handleNotesChange = (newNotes) => {
		setNotes(newNotes);
		debouncedSaveNotes(newNotes);
	}


	const getDatabase = () => {
		setDbLoading(true);
		axios.get(`/api/getInstagramDBItem/${id}`)
			.then(response => {
				const data = response.data;
				if (data.success) {
					const restaurant = data.restaurant;
					const notes = data.item.personalNotes;
					const medias = data.item.instagramMedias;
					setRestaurant(restaurant);
					setNotes(notes || "");
					setImages(medias);
					setDbLoading(false);
					setSelectedState(data.restaurant.status);
				} else {
					console.log("Error getting restaurant data : ", data.error);
				}
			});
	}


	const saveNotes = async (note) => {
		try {
			let data = {
				personalNotes: note,
				instagramId: id
			}
			const response = await axios.post("/api/updateInstagramDBItemNotes/", data);
			if (response.data.success) {
				console.log("Notes saved");
			} else {
				console.log("Error saving last notes");
			}
		}
		catch (error) {
			console.error(error);
		}
	};


	const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    };


	const debouncedSaveNotes = useCallback(debounce(saveNotes, 2000), []);


	return (
		<>
			<div className="flex flex-col w-full justify-center pt-4">
				<h1 className='text-4xl font-bold'>Welcome to<br/><span className='italic'>Tuco Databases</span></h1>
				<div className="flex justify-center items-center pt-4">
					<hr className="w-2/12 border-tremor-gab-dark" />
				</div>
			</div>
			<div className="flex w-11/12 justify-center items-center pt-12 ml-12">
				<Card className="relative w-full justify-center overflow-auto max-h-[calc(105vh-220px)] border-tremor-tuco-talon" decoration="left">
					{ dbLoading === false ? (
						<>
						<p className="text-left text-blue-400 underline mb-4"><Link to="/databases/instagram">&lt;- Back to Databases</Link></p>
						<Card className="flex justify-start items-center border-tremor-tuco-green" decoration="left">
							<div className="flex gap-4 justify-between items-center">
								<img src={restaurant['profilePictureUrl']} alt="Profile Picture" className="rounded-full" style={{ width: '72px', height: '72px' }} />
								<h3 className="text-left font-extrabold mb-2 ml-2">@{restaurant['username']}</h3>
							</div>
							<div className="flex justify-end items-center ml-auto">
								<div className="flex flex-col justify-center items-end mr-10">
									<h4 className="text-right font-bold">Followers count : {restaurant['followersCount']}</h4>
									<h4 className="text-right font-bold">Follows count : {restaurant['followsCount']}</h4>
								</div>
								<div className="flex flex-col justify-center items-center gap-1">
									<button className="btn-tuco-bis w-36" onClick={() => {
											window.open(`${restaurant['instagramLink']}`, '_blank');
										}}
									>Open Instagram</button>
									<button className="btn-tuco w-36" onClick={() => {
											window.open(`${restaurant['website']}`, '_blank');
										}}
									>Open Website</button>
								</div>
							</div>
						</Card>
						<Card className="flex justify-center items-center mt-2 border-tremor-tuco-green" decoration="left">
							<Grid container spacing={2} className="flex justify-between">
								<Grid item xs={9} className="flex justify-start items-center gap-2">
									<h4 className="text-center font-bold">"{restaurant['biography']}"</h4>
								</Grid>
								<Grid item xs={3} className="flex justify-end items-center gap-2">
									<div className="flex gap-3 pr-5">
										<button onClick={() => setImageDialogOpen(true)} className="btn-tuco w-36">View Images</button>
									</div>
								</Grid>
							</Grid>
						</Card>
						<Grid container spacing={2} className="flex justify-center pt-4">
							<Grid item xs={12} className="flex justify-center items-center gap-4">
								<StateButtons selectedState={selectedState} setSelectedState={setSelectedState} restaurant={restaurant} />
							</Grid>
							<Grid item xs={12} className="flex">
								<EmptyTextarea value={notes} onChange={handleNotesChange} />
							</Grid>
						</Grid>
						</>
					) : (
						<div className="flex justify-center items-center">
							<TucoLogo />
						</div>
					)}
				</Card>
			</div>
			<Dialog open={imageDialogOpen} onClose={(val) => setImageDialogOpen(val)} static={true}>
				<DialogPanel className="flex justify-center">
					<ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
						{ images.length > 0 && images.map((image, index) => (
							image.media_url === "" ? (
								null
							) : (
								<ImageListItem key={image.media_url}>
									<img src={image.media_url} alt={`Image ${index + 1}`} style={{ width: '100%', height: 'auto' }} />
								</ImageListItem>
							)
						))}
					</ImageList>
				</DialogPanel>
			</Dialog>
		</>
	);
}
