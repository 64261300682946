import * as React from 'react';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';


const Textarea = styled(BaseTextareaAutosize)(
	({ theme }) => `
	box-sizing: border-box;
	font-family: 'IBM Plex Sans', sans-serif;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.5;
	padding: 12px;
	border-radius: 12px 12px 0 12px;
	border: 1px solid;
`,
);


export default function EmptyTextarea(props) {
	const { value, onChange } = props;


	const handleChange = (e) => {
		const newValue = e.target.value;
		onChange(newValue);
	};


	return <Textarea className='bg-tremor-tuco-latte w-full
				focus:outline-none focus:border-tremor-gab-dark'
				value={value} onChange={handleChange} placeholder="Type notes here"/>;
}