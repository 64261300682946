import './App.css';
import './index.css';
import React from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from './components/navbar';
import AdminRoutes from './components/adminRoutes';
import FormRoutes from './components/formRoutes';
import WebsiteRoutes from './components/WebsiteRoutes';


function isSpecificDevelopment() {
	return window.location.hostname === 'localhost' || window.location.hostname === '192.168.1.117';
}


function App() {

	useEffect(() => {
		axios.interceptors.request.use(config => {
			const token = getCsrfToken();
			if (token) {
			config.headers['X-CSRFToken'] = token;
			}
			return config;
		}, error => {
			return Promise.reject(error);
		});
	}, []);


	function getCsrfToken() {
		return document.cookie.split('; ').find(row => row.startsWith('csrftoken='))?.split('=')[1];
	}


	const subdomain = window.location.hostname.split('.')[0];
	const isDevelopment = isSpecificDevelopment();


	return (
		<Router>
			<div className="App min-h-screen bg-tremor-tuco-dark w-full">
				{ isDevelopment ? (
					<>
						<Navbar />
						<div className='min-h-screen'>
							<AdminRoutes />
						</div>
					</>
				) : ( subdomain === "form" ? (
					<FormRoutes />
				) : ( subdomain === "platform" ? (
					<>
						<Navbar />
						<div className='min-h-screen'>
							<AdminRoutes />
						</div>
					</>
				) : ( <WebsiteRoutes /> )))}
			</div>
		</Router>
	);
}

export default App;
