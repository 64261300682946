import React, { useEffect } from "react";
import { RiHomeLine, RiToolsFill, RiInstagramLine, RiUser6Line } from "@remixicon/react";
import { useNavigate } from "react-router-dom";
import { useGlobal } from "./globalContext";
import { RiArrowRightSLine } from "@remixicon/react";
import { motion, AnimatePresence } from "framer-motion";

export default function Navbar() {
	const navigate = useNavigate();
	const { navbarExpanded, setNavbarExpanded } = useGlobal();

	useEffect(() => {
        function handleClickOutside(event) {
            const navbar = document.querySelector('.navbar-expanded'); // Assurez-vous que cette classe est unique à votre navbar
            if (navbar && !navbar.contains(event.target)) {
                setNavbarExpanded(false);
            }
        }

        if (navbarExpanded) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [navbarExpanded, setNavbarExpanded]);

	function handleNavItemClick (path) {
		navigate(path);
	}

	function NavItem ({icon, text, path}) {
		return (
			<div className="nav-item" onClick={() => handleNavItemClick(path)}>
				<div className="fixed left-6 mt-2">
					{icon}
				</div>
				<div className="fixed left-16 mt-2">
					<p>{text}</p>
				</div>
			</div>
		);
	}

	const navbarStyle = {
        transform: navbarExpanded ? 'translateX(0)' : 'translateX(-100%)',
        transition: 'transform 0.3s ease'
    };

	return (
		<>
		<AnimatePresence>
			{navbarExpanded && 
				<motion.div initial={{ x: '-100%' }} animate={{ x: 0 }} exit={{ x: '-100%' }} transition={{ duration: 0.3 }}
					className="fixed top-0 left-0 h-screen w-52 m-0 flex flex-col justify-center items-start z-50" style={navbarStyle}>
					<div className="navbar-expanded bg-tremor-tuco-latte text-tremor-tuco-dark rounded-r-3xl border-y-2 border-r-2 border-black" style={{ height: 'fit-content' }}>
						<div className="mt-4">
							<NavItem icon={<RiHomeLine />} text="Home" path="/" />
							<NavItem icon={<RiToolsFill />} text="Tools" path="/tools" />
							<NavItem icon={<RiInstagramLine />} text="Instagram" path="/databases/instagram" />
							<NavItem icon={<RiUser6Line />} text="Clients" path="/clients" />
						</div>
					</div>
				</motion.div>
			}
		</AnimatePresence>
		<AnimatePresence>
			{!navbarExpanded &&
				<motion.div initial={{ x: '-100%' }} animate={{ x: 0 }} exit={{ opacity: 0.2, x: '-100%' }} transition={{ duration: 0.3, delay: 0.1 }}
					className="fixed top-0 left-0 h-screen w-16 m-0 flex flex-col justify-center items-start z-50">
					<div className="bg-tremor-tuco-latte text-tremor-tuco-dark rounded-r-2xl py-4 border-2 border-black nav-hover hover:py-6" style={{ height: 'fit-content' }}>
						<RiArrowRightSLine className="nav-arrow" onClick={() => setNavbarExpanded(true)} />
					</div>
				</motion.div>
			}
		</AnimatePresence>
		</>
	);
}

