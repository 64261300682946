import React, {useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Grid } from "@mui/material";
import {Card, CardHeader, CardBody, CardFooter} from "@nextui-org/card";
import { RiInstagramLine } from "@remixicon/react";
import { InstagramSVG } from "./misc/InstagramSVG";
import { Tuco } from "./misc/Tuco";
import { Divider } from "@tremor/react";
import { Button, ButtonGroup } from "@nextui-org/button";

export default function Toolspage() {

	const handleInstagramDB = () => {
		window.location.href = '/databases/instagram';
	}


	const handleFirstContact = () => {
		window.location.href = '/forms/firstcontact';
	}


	const handleFormDetails = () => {
		window.location.href = '/forms/details';
	}


	const handleRestauForm = () => {
		window.location.href = '/forms/restau';
	}


	const handleTest = () => {
		axios.get('/api/test')
			.then(response => {
				console.log(response);
			})
			.catch(error => {
				console.log(error);
			});
	}


	return (
		<Grid container spacing={2} className="flex h-2/12 justify-center items-top pt-4 pl-4">
			<Grid item xs={12} md={6} className="flex justify-center">
				<Card isBlurred className="w-10/12">
					<CardBody>
						<Grid container spacing={1} className="flex justify-between items-center">
							<Grid item xs={3} className="relative top-1 left-1">
								<InstagramSVG />
							</Grid>
							<Grid item xs={9} className="flex justify-left">
								<h2 className="text-2xl font-semibold">Instagram database</h2>
							</Grid>
							<Grid item xs={12} className="flex justify-center w-full">
								<ButtonGroup className="w-full" fullWidth>
									<Button onClick={handleInstagramDB}>DB List</Button>
									{/* <Button onClick={handleInstagramDB}>Received forms</Button> */}
								</ButtonGroup>
							</Grid>
						</Grid>
					</CardBody>
				</Card>
			</Grid>
			<Grid item xs={12} md={6} className="flex justify-center">
				<Card isBlurred className="w-10/12">
					<CardBody>
						<Grid container spacing={1} className="flex justify-between items-center">
							<Grid item xs={12} className="flex justify-center items-center">
								<Tuco />
								<h2 className="text-2xl font-semibold">Tuco forms</h2>
							</Grid>
							<Grid item xs={12} className="flex justify-center w-full">
								<ButtonGroup className="w-full" fullWidth>
									<Button onClick={handleFirstContact}>First contact form</Button>
									<Button onClick={handleFormDetails}>Received forms</Button>
								</ButtonGroup>
							</Grid>
							<Grid item xs={12} className="flex justify-center w-full">
								<ButtonGroup className="w-full" fullWidth>
									<Button onClick={handleRestauForm}>Restaurant form</Button>
									<Button onClick={handleFormDetails}>Received forms</Button>
								</ButtonGroup>
							</Grid>
						</Grid>
					</CardBody>
				</Card>
			</Grid>
			<Grid item xs={12} md={6} className="flex justify-center">
				<Card isBlurred className="w-10/12">
					<CardBody>
						<Grid container spacing={1} className="flex justify-between items-center">
							<Grid item xs={12} className="flex justify-center items-center">
								<Tuco />
								<h2 className="text-2xl font-semibold">Tuco cryptos</h2>
							</Grid>
							<Grid item xs={12} className="flex justify-center w-full">
								<ButtonGroup className="w-full" fullWidth>
									<button className="btn-tuco" onClick={handleTest}>BaBreton</button>
								</ButtonGroup>
							</Grid>
						</Grid>
					</CardBody>
				</Card>
			</Grid>
		</Grid>
	);
}