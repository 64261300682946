import React from "react";
import PlatformBackground from "./PlatformBackground";


export default function Homepage() {
	return (
		<PlatformBackground>
			<div className="flex h-screen w-full justify-center items-center">
				<h1 className='text-4xl font-bold text-white'>Welcome to<br/><span className='italic'>Tuco Platform</span></h1>
			</div>
		</PlatformBackground>
	);
}