import React, {useState, useEffect, useRef } from "react";
import axios from "axios";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Tooltip,
	Card, CardHeader, CardBody, Divider, Chip,
	Input, Autocomplete, AutocompleteItem,
	Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button } from "@nextui-org/react";
import { Dialog, DialogPanel } from "@tremor/react";
import TempClientCard from "./TempClientCard";
import { useGlobal } from "./globalContext";
import { RiCheckLine } from "@remixicon/react";
import { DeleteIcon } from "./misc/DeleteIcon";
import { SearchIcon } from "./misc/SearchIcon";
import { useNavigate } from "react-router-dom";
import PlatformBackground from "./PlatformBackground";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@tremor/react';
import ClientsDetails from "./ClientsDetails";


const ClientsPage = () => {

	const [clients, setClients] = useState([]);
	const [filters, setFilters] = useState([]);
	const [filterSelected, setFilterSelected] = useState(new Set(["Nom"]));
	const [stateSelected, setStateSelected] = useState('');
	const [tempClients, setTempClients] = useState([]);
	const [tempClientData, setTempClientData] = useState({});
	const [showTempDialog, setShowTempDialog] = useState(false);
	const [action, setAction] = useState('');
	const { stateColors, stateNames, styleNames, states, stateItems } = useGlobal();
	const [searchQuery, setSearchQuery] = useState("");
	const [clientDetailShow, setClientDetailShow] = useState(false);
	const [clientDetailId, setClientDetailId] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		getClientsData();
	}, []);

	const selectedFilter = React.useMemo(
		() => Array.from(filterSelected).join(', ').replaceAll('_', ' '),
		[filterSelected]
	);

	const getClientsData = () => {
		axios.get(`/api/getClients/`)
		.then(response => {
			if (response.data.success) {
				setClients(response.data.clients);
				setTempClients(response.data.tempClients);
			} else {
				throw new Error('Une erreur inattendue est survenue. Veuillez réessayer plus tard.');
			}
		})
		.catch(error => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			alert(errorMessage);
		});
	}

	const getClientData = async (key) => {
		try {
			const response = await axios.get(`/api/getTempClient/${key}/`);
			if (response.data.success) {
				setTempClientData(response.data.tempClient);
				return true;
			} else {
				throw new Error('Une erreur inattendue est survenue. Veuillez réessayer plus tard.');
			}
		} catch (error) {
			const errorMessage = error.response ? error.response.data.message : error.message;
			alert(errorMessage);
			return false;
		}
	}

	const handleTempAction = async () => {
		let data = {};
		if (action === 'valid') {
			data = {
				key: tempClientData[0].key,
				action: 'valid'
			}
		} else if (action === 'delete') {
			data = {
				key: tempClientData[0].key,
				action: 'delete'
			}
		}
		try {
			const response = await axios.post(`/api/updateTempClient/`, data);
			if (response.data.success) {
				getClientsData();
				setShowTempDialog(false);
			} else {
				throw new Error('Une erreur inattendue est survenue. Veuillez réessayer plus tard.');
			}
		} catch (error) {
			const errorMessage = error.response ? error.response.data.message : error.message;
			alert(errorMessage);
		}
	}
	
	const handleTooltip = async (action, key) => {
		setAction(action);
		const success = await getClientData(key);
		if (success) {
			setShowTempDialog(true);
		}
	}

	const filteredClients = clients.filter(client => {
		return Object.entries(filters).every(([key, value]) => {
		  if (!value) return true;
		  if (key === 'state') return client.state === value;
		  return true;
		}) && client.restaurantName.toLowerCase().includes(searchQuery.toLowerCase());
	});

	const handleUpdateFilters = (filterKey, value) => {
		setFilters(prevFilters => {
		  if (prevFilters[filterKey] === value) {
			setStateSelected('');
			const { [filterKey]: _, ...newFilters } = prevFilters;
			return newFilters;
		  }
		  return { ...prevFilters, [filterKey]: value };
		});
	};

	const handleFilterState = (state) => {
		console.log(state);
		handleUpdateFilters('state', state);
		setStateSelected(state ?? '');
	}

	const handleNavClient = (id) => {
		setClientDetailId(id);
		setClientDetailShow(true);
		if (id === clientDetailId) {
			setClientDetailShow(!clientDetailShow);
		}
	}


	return (
		<>
			<PlatformBackground>
				<div className="grid grid-rows-24 h-screen grid-cols-24 gap-2 p-2">

					<Card key="card1" className="dark bg-transparent row-span-24 col-span-6 rounded-lg overflow-y-auto scroll-on-hidden">
						<TabGroup>
							<TabList className="flex justify-center items-center bg-tremor-tuco-latte">
								<Tab className="tuco-undertitle-2 text-tremor-tuco-dark font-bold text-xl">Clients</Tab>
								<Tab className="tuco-undertitle-2 text-tremor-tuco-dark font-bold text-xl">Clients à vérifier</Tab>
							</TabList>
							<TabPanels>
								<TabPanel className="container-col gap-2">
									<div className="container-col gap-2 w-full bg-tremor-tuco-talon p-4 rounded-lg" style={{ height: 'fit-content' }}>
										<Dropdown>
											<DropdownTrigger>
												<Button className="bg-tremor-tuco-latte text-black" variant="bordered">Filtré par : {selectedFilter}</Button>
											</DropdownTrigger>
											<DropdownMenu variant="flat" selectionMode="single" selectedKeys={filterSelected} onSelectionChange={setFilterSelected}>
												<DropdownItem key="Nom">Nom</DropdownItem>
												<DropdownItem key="État">État</DropdownItem>
											</DropdownMenu>
										</Dropdown>
										{filterSelected.has('Nom') && (
										<Input
											label="Search"
											isClearable
											radius="lg"
											placeholder="Type to search..."
											value={searchQuery}
											onChange={(e) => {setSearchQuery(e.target.value)}}
											startContent={
											<SearchIcon className="text-white mb-0.5 pointer-events-none flex-shrink-0" />
											}
										/>)}
										{filterSelected.has('État') && (
										<Autocomplete
											label="Filter by state"
											placeholder="Select a state"
											radius="lg"
											defaultItems={stateItems}
											selectedKey={stateSelected}
											isClearable
											onSelectionChange={(selectedKey) => {
												if(selectedKey === null || typeof selectedKey === 'undefined') {
													handleFilterState(null);
												} else {
													const selectedItem = stateItems.find(item => item.value === selectedKey);
													if (selectedItem) {
														handleFilterState(selectedItem.value);
													}
												}
											}}
											>
												{(item) => <AutocompleteItem key={item.value}>{item.label}</AutocompleteItem>}
											</Autocomplete>)}
									</div>
									<div className="grid grid-cols-24 gap-2 p-2 w-full" style={{ height: 'fit-content' }}>
										{filteredClients.map((client) => (
											<Card isPressable key={client.key} id={client.key} className="light col-span-24 cursor-pointer hover:border-2 hover:border-blue-500" onPress={() => handleNavClient(client.key)}>
												<CardBody className="flex flex-col overflow-hidden">
													<div className="flex justify-between items-center">
														<p className="tuco-title-2 text-xl font-bold uppercase">{client.restaurantName}</p>
														<p className="tuco-undertitle-2 text-sm">{client.created}</p>
													</div>
													<div className="flex justify-between items-center">
														<p className="tuco-text text-sm">{styleNames[client.style]}</p>
														<Chip classNames={{content: "text-white text-xs"}} style={{ backgroundColor: stateColors[client.state] }}>{stateNames[client.state]}</Chip>
													</div>
												</CardBody>
											</Card>
										))}
									</div>
								</TabPanel>
								<TabPanel>
									<Table isHeaderSticky className="light px-2 pb-2 w-full" aria-label="Liste des clients à vérifier">
										<TableHeader>
											<TableColumn className="text-left tuco-title-2 text-lg text-white bg-tremor-tuco-talon" key="date">Date</TableColumn>
											<TableColumn className="text-center tuco-title-2 text-lg text-white bg-tremor-tuco-talon" key="restaurant">Restaurant</TableColumn>
											<TableColumn className="text-center tuco-title-2 text-lg text-white bg-tremor-tuco-talon" key="actions">Actions</TableColumn>
										</TableHeader>
										<TableBody>
											{tempClients.map((client) => (
												<TableRow key={client.key} className="min-h-fit">
													<TableCell className="text-tremor-tuco-dark text-left text-xs">{client.created}</TableCell>
													<TableCell className="text-tremor-tuco-dark">{client.restaurantName}</TableCell>
													<TableCell>
														<div className="flex flex-row items-center gap-2">
															<Tooltip color="success" content="Valid user" className="h-fit">
																<span aria-label={`Valider le client ${client.restaurantName}`} className="text-lg text-danger cursor-pointer active:opacity-50" id="valid" onClick={() => handleTooltip('valid', client.key)}>
																	<RiCheckLine color="#64CD8A" />
																</span>
															</Tooltip>
															<Tooltip color="danger" content="Delete user">
																<span aria-label={`Supprimer le client ${client.restaurantName}`} className="text-lg text-danger cursor-pointer active:opacity-50" id="delete" onClick={() => handleTooltip('delete', client.key)}>
																	<DeleteIcon />
																</span>
															</Tooltip>
														</div>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TabPanel>
							</TabPanels>
						</TabGroup>
					</Card>
					<Card className="bg-white/30 row-span-24 col-span-18 rounded-lg">
						{clientDetailShow &&
							<ClientsDetails id={clientDetailId} />
						}
					</Card>
				</div>
				<Dialog open={showTempDialog} onClose={() => setShowTempDialog(false)} static={true} className="z-[100]">
					<DialogPanel className="flex flex-col justify-between items-center bg-tremor-tuco-latte">
						{action === 'valid' ? (
							<p className="tuco-undertitle-2 py-2">Valider le client ?</p>
						) : action === 'delete' ? (
							<p className="tuco-undertitle-2 py-2">Supprimer le client ?</p>
						) : null}
						<TempClientCard data={tempClientData} />
						<div className="flex gap-2 pt-2">
							<button className="btn-tuco-green" onClick={handleTempAction}>Valider</button>
							<button className="btn-tuco-red" onClick={() => setShowTempDialog(false)}>Annuler</button>
						</div>
					</DialogPanel>
				</Dialog>
			</PlatformBackground>
		</>
	);
}

export default ClientsPage;