import React, {useState, useEffect, useCallback } from "react";
import axios from "axios";
import{ Link, useParams} from "react-router-dom";
import { Divider, Grid } from "@mui/material";
import { Card, CardHeader, CardBody, CardFooter } from "@nextui-org/card";
import { Button, ButtonGroup } from "@nextui-org/button";
import FirstContact from "./forms/FirstContact";
import { set } from "lodash";

export default function FormDetails() {

	const [forms, setForms] = useState([]);


	useEffect(() => {
		getFirstContact();
	}, []);


	const getFirstContact = () => {
		axios.get('/api/getFirstContactForms/')
			.then(response => {
				setForms(response.data.forms);
				console.log(response.data.forms);
			})
			.catch(error => {
				console.log(error);
			});
	}


	function formatDate(dateString) {
		const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
		return new Date(dateString).toLocaleDateString('fr-FR', options);
	  }


	return (
		<>
			<div>
				<Grid container spacing={1} className="flex justify-center h-screen items-center w-6/12">
					<Grid item xs={12} className="flex flex-col justify-center">
						<Card className="w-9/12 max-h-screen">
							<CardBody>
							<Grid container className="flex justify-center items-center">
								<Grid item xs={2}>
									<p className="font-bold text-left">Date</p>
								</Grid>
								<Grid item xs={2}>
									<p className="font-bold text-center">Entreprise</p>
								</Grid>
								<Grid item xs={2}>
									<p className="font-bold text-center">Instagram</p>
								</Grid>
								<Grid item xs={2}>
									<p className="font-bold text-center">Contact</p>
								</Grid>
								<Grid item xs={2}>
									<p className="font-bold text-center">Type de restaurant</p>
								</Grid>
								<Grid item xs={2}>
									<p className="font-bold text-center">Téléphone</p>
								</Grid>
							</Grid>
							<br />
							{forms.map((form, index) => (
								<>
									<Grid container spacing={1} className="flex justify-center items-center">
										<Grid item xs={2}>
											<p className="font-bold text-left">{formatDate(form.created)}</p>
										</Grid>
										<Grid item xs={2}>
											<p className="font-semi-bold text-center">{form.entrepriseName}</p>
										</Grid>
										<Grid item xs={2}>
											<p className="font-semi-bold text-center">@{form.instagramName}</p>
										</Grid>
										<Grid item xs={2}>
											<p className="font-semi-bold text-center">{form.contact}</p>
										</Grid>
										<Grid item xs={2}>
											<p className="font-semi-bold text-center">{form.type}</p>
										</Grid>
										<Grid item xs={2}>
											<p className="font-semi-bold text-center">{form.phone}</p>
										</Grid>
									</Grid>
									<br />
								</>
								))}
							</CardBody>
						</Card>
					</Grid>
				</Grid>
			</div>
		</>
	);
}