import React, { useState, useEffect, useRef } from "react";
import{ useNavigate, useLocation } from "react-router-dom";
import { Tuco } from "../misc/Tuco";
import { motion } from "framer-motion";
import { Input } from "@nextui-org/react";
import axios from "axios";

export default function RestauForm4() {

	const navigate = useNavigate();
	const location = useLocation();
	const [restaurantName, setRestaurantName] = useState('');
	const [restaurantId, setRestaurantId] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [objectives, setObjectives] = useState([]);
	const [style, setStyle] = useState('');
	const [adjectives, setAdjectives] = useState([]);
	const scrollRef = useRef(null);
	const [under750, setUnder750] = useState(window.innerHeight < 750);


	useEffect(() => {
		const query = new URLSearchParams(location.search);
		const restaurantName = query.get('restaurantName');
		const id = query.get('id');
		if (!restaurantName || !id) {
			navigate('/forms/restau/1');
		}
		setRestaurantName(decodeURIComponent(restaurantName));
		setRestaurantId(id);
		getRestaurantData(id);
	}, [location.search, navigate]);


	useEffect(() => {
		if (scrollRef.current) {
			const scroll = scrollRef.current;
			const scrollPos = (scroll.scrollWidth - scroll.clientWidth) / 2;
			scroll.scrollLeft = scrollPos;
		}
	}, [adjectives]);


	const getRestaurantData = (id) => {
		axios.get(`/api/restauForm/getData/${id}/`)
		.then(response => {
			if (response.data.success) {
				const { objectives, style, adjectives, concept, identity } = response.data.data;
				setObjectives(objectives);
				const styleArray = JSON.parse(style.replace(/'/g, '"'));
				setStyle(styleArray[0]);
				setAdjectives(adjectives);
				console.log(response.data.data);
			} else {
				throw new Error('Une erreur inattendue est survenue. Veuillez réessayer plus tard.');
			}
		})
		.catch(error => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			alert(errorMessage);
		});
	}


	const handleClickAnimate = () => {
		if (firstName === '' || lastName === '' || email === '') {
			alert('Veuillez remplir les champs obligatoires.');
			return;
		}
		const data = {
			restaurantName: restaurantName,
			restaurantId: restaurantId,
			firstName: firstName,
			lastName: lastName,
			email: email,
			phone: phone
		};
		axios.post('/api/restauForm/new/infos/', data)
		.then(response => {
			if (response.data.success) {
				navigate('/forms/done');
			} else {
				throw new Error('Une erreur inattendue est survenue. Veuillez réessayer plus tard.');
			}
		})
		.catch(error => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			alert(errorMessage);
		});
	}


	const objectivesList = {
		ticket: "Augmenter le ticket moyen",
		upgrade: "Améliorer la satisfaction client",
		attirate: "Attirer une nouvelle clientèle",
		introduction: "Introduire de nouveaux plats",
		reinforce: "Renforcer votre image de marque"
	};


	const stylesList = {
		minimalist: "minimaliste",
		sophistic: "sophistiqué",
		classic: "classique",
		fun: "fun",
		bistro: "bistro - café",
		high: "haut de gamme",
		fastfood: "fast-food",
		world: "cuisine du monde",
	};


	return (
		<>
			<motion.div	initial={{ opacity:0, scale:0.8 }} animate={{ opacity:1, scale:1 }} transition={{ duration: 1.2, delay: 0.3 }}
						exit={{ opacity:0, scale:0.5, transition: { duration: 0.5, delay: 0.2 } }}
						className={`grid grid-rows-12 grid-cols-24 gap-2 h-screen w-full justify-center items-center p-2 relative`}>
				<div className="pt-4 row-span-2 col-span-24 flex flex-col justify-center items-center">
					<Tuco />
					<p className="tuco-subtitle-white text-center">Merci {restaurantName} !</p>
					{ objectives.length === 1 ? <p className="tuco-text-2-white phone:text-md text-center pt-2">Nous allons réaliser votre maquette en respectant cet objectif : </p> : <p className="tuco-text-2-white phone:text-md text-center pt-2">Nous allons réaliser votre maquette en respectant ces objectifs : </p> }
				</div>
				<div className="pt-8 row-span-4 col-span-24 flex flex-col justify-start items-center">
					<div className="flex w-screen h-full justify-center items-center gap-2 bg-tremor-tuco-green-smooth p-1" style={ under750 ? { maxHeight: '64px' } : { maxHeight: '64px' } }>
						{objectives.map((objective, index) => {
							return (
								<p key={index} className="tuco-text-2 phone:text-md text-center p-1">{objectivesList[objective]}</p>
							);
						})}
					</div>
					<div className="w-full h-full flex flex-col justify-center items-center">
						<p className="tuco-text-2-white phone:text-md text-center pt-4 pb-4">Vous souhaitez un style <span className="tuco-text-2-green-smooth phone:text-md">{stylesList[style]}</span> et les adjectifs définnissant votre établissement sont : </p>
						<div ref={scrollRef} className={`flex space-x-4 overflow-x-auto p-1 bg-tremor-tuco-green-smooth w-screen items-center ${adjectives.length < 3 ? "justify-center" : null } `} style={ under750 ? { maxHeight: '32px', whiteSpace: 'nowrap' } : { maxHeight: '64px', whiteSpace: 'nowrap' } }>
							{adjectives.map((adjective, index) => (
								<span key={index} className="tuco-text-2 phone:text-md p-1">
								{adjective}
								</span>
							))}
						</div>
					</div>
				</div>
				<div className="row-span-4 col-span-24 grid grid-cols-24 gap-2 justify-center items-center w-full px-4 rounded-3xl">
					<p className={`tuco-text-2-white ${under750 && "text-sm"} text-center col-span-24`}>Pour obtenir votre maquette, veuillez remplir ces informations.</p>
					<div className="col-span-12">
						<Input isRequired label="Nom" value={lastName} onValueChange={setLastName} placeholder="Nom" />
					</div>
					<div className="col-span-12">
						<Input isRequired label="Prénom" value={firstName} onValueChange={setFirstName} placeholder="Prénom" />
					</div>
					<div className="col-span-24">
						<Input isRequired label="Email" value={email} onValueChange={setEmail} placeholder="Email" />
					</div>
					<div className="col-span-24">
						<Input label="Téléphone" value={phone} onValueChange={setPhone} placeholder="Numéro de téléphone" />
					</div>
				</div>
				<div className="row-span-2 col-span-24 flex flex-col justify-start items-center">
					<button className="btn-tuco-beige-uh w-4/12 mt-2 text-black shadow-lg" onClick={handleClickAnimate}>Terminer</button>
					<p className="tuco-text-white text-xs text-center pt-2">En cliquant sur ce bouton vous acceptez notre <span className="tuco-text-2-green-smooth text-xs underline"><a href="/website/confidentiality" target="_blank" rel="noreferrer">politique de confidentialité</a></span>.</p>
				</div>
			</motion.div>
		</>
	);
}