import React from "react";

export const TucoLogo = () => (
  <img
    alt="Tuco logo"
	src="https://i.ibb.co/RpYVL8W/logo-final.png"
	style={{ width: "96px", height: "96px"}}
	className="animate-spin"
  />
);
