import React, {useState, useEffect, useCallback } from "react";
import axios from "axios";
import{ Link, useParams} from "react-router-dom";

import { RiCheckLine } from "@remixicon/react";

export default function StateButtons({ selectedState, setSelectedState, restaurant }) {

	const handleClick = (e) => {
		const oldState = selectedState;
		const newState = e.target.id;
		const data = {
			username: restaurant.username,
			state: newState
		};
		axios.post("/api/updateStatus/", data)
			.then(response => {
				if (response.data.success) {
					setSelectedState(newState);
				} else {
					setSelectedState(oldState);
				}
			})
			.catch(error => {
				console.error(error);
			});
	}

	return (
		<div className="flex gap-3">
			<button id="none" onClick={handleClick} className="flex justify-center items-center btn-tuco-none">
				Aucun
				{selectedState === "none" && <RiCheckLine />}
			</button>
			<button id="interesting" onClick={handleClick} className="flex justify-center items-center btn-tuco-interesting">
				Profil intéressant
				{selectedState === "interesting" && <RiCheckLine />}
			</button>
			<button id="contacted" onClick={handleClick} className="flex justify-center items-center btn-tuco-contacted">
				Message envoyé
				{selectedState === "contacted" && <RiCheckLine />}
			</button>
		</div>
	);
}