import React, { useState, useEffect } from "react";
import{ useNavigate, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import CheckCircle from "../misc/CheckCircle";
import axios from "axios";

export default function RestauForm2() {

	const navigate = useNavigate();
	const location = useLocation();
	const [restaurantName, setRestaurantName] = useState('');
	const [restaurantId, setRestaurantId] = useState('');
	const [objectivesSelected, setObjectivesSelected] = useState([]);
	const [hasBeenSelected, setHasBeenSelected] = useState(false);
	const [isExiting, setIsExiting] = useState(false);
	const [nextPage, setNextPage] = useState(false);
	const [under750, setUnder750] = useState(window.innerHeight < 750);


	useEffect(() => {
		function handleResize() {
			setUnder750(window.innerHeight < 750);
		}
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => {
			window.removeEventListener('resize', handleResize);
		}
	}, []);


	const circleSize = under750 ? "w-[75px] h-[75px]" : "w-[95px] h-[95px]";
    const emojiSize = under750 ? "w-12 h-12" : "w-16 h-16";


	useEffect(() => {
		const query = new URLSearchParams(location.search);
		const restaurantName = query.get('restaurantName');
		const id = query.get('id');
		if (!restaurantName || !id) {
			navigate('/forms/restau/1');
		}
		setRestaurantName(decodeURIComponent(restaurantName));
		setRestaurantId(id);
		setObjectivesSelected([]);
	}, [location.search, navigate]);


	useEffect(() => {
		if (objectivesSelected.length > 0) {
			setHasBeenSelected(true);
		}
	}, [objectivesSelected]);


	const handleClickAnimate = () => {
		setNextPage(true);
		const data = {
			restaurantName: restaurantName,
			restaurantId: restaurantId,
			objectives: objectivesSelected
		};
		axios.post('/api/restauForm/new/objectives/', data)
		.then(response => {
			if (response.data.success) {
				setIsExiting(true);
				const encodedRestaurantName = encodeURIComponent(restaurantName);
				navigate(`/forms/restau/3?restaurantName=${encodedRestaurantName}&id=${restaurantId}`);
			} else {
				throw new Error('Une erreur inattendue est survenue. Veuillez réessayer plus tard.');
			}
		})
		.catch(error => {
			const errorMessage = error.response ? error.response.data.message : error.message;
			alert(errorMessage);
		});
	}


	const handleObjectiveSelected = (e) => {
        const id = e.currentTarget.id;
        setObjectivesSelected(currentSelection => {
            if (currentSelection.includes(id)) {
                return currentSelection.filter(objective => objective !== id);
            }
            if (currentSelection.length < 2) {
                return [...currentSelection, id];
            }
            return currentSelection;
        });
    };


	const exitAnimation = {
		deselected: { opacity:0, rotate:-360, transition: { duration: 0.5 } },
		nextPage: under750 ? { opacity:0, y:'35%', x:'-100%', rotate:-90, transition: { duration: 1 } } : { opacity:0, y:'50%', x:'-100%', rotate:-90, transition: { duration: 1 } }
	};


	return (
		<>
			<motion.div id="bento" initial={{ opacity:0, scale:0.8, }} animate={{ opacity:1, scale:1 }} transition={{ duration: 1.2 }}
						exit={{ opacity:0, transition: { duration: 0.5 } }}
						className={`grid grid-rows-10 grid-cols-24 gap-2 h-screen w-full justify-center items-center p-2 relative`}>
				<div className={`row-span-2 col-span-24 w-full h-full pt-10 ${under750 && "mb-4 pt-5"}`}>
					<p className="tuco-title-white text-center mb-3">Quels sont vos objectifs ?</p>
					<p className="tuco-text-white phone:text-md text-center">Selectionnez-en deux pour votre restaurant.</p>
				</div>
				<div className={`ticket-box ${ objectivesSelected.includes("ticket") ? ('box-selected') : ('box') }`} id="ticket" onClick={handleObjectiveSelected}>
					<img className="ticket-img" src="/imgs/humans/ticket.png" alt="ticket" />
					<CheckCircle className="absolute top-1 right-1" size="24px" fill={objectivesSelected.includes("ticket")} />
					<p className="ticket-text">Augmenter le ticket moyen.</p>
				</div>
				<div className={`upgrade-box ${ objectivesSelected.includes("upgrade") ? ('box-selected') : ('box-2') }`} id="upgrade" onClick={handleObjectiveSelected}>
					<img className="upgrade-img" src="/imgs/humans/upgrade.png" alt="upgrade" />
					<CheckCircle className="absolute top-1 right-1" size="24px" fill={objectivesSelected.includes("upgrade")} />
					<p className="upgrade-text">Améliorer la satisfaction client.</p>
				</div>
				<div className={`attirate-box ${ objectivesSelected.includes("attirate") ? ('box-selected') : ('box') }`} id="attirate" onClick={handleObjectiveSelected}>
					<img className="attirate-img" src="/imgs/humans/attirate.png" alt="attirate" />
					<CheckCircle className="absolute top-1 right-1" size="24px" fill={objectivesSelected.includes("attirate")} />
					<p className="attirate-text">Attirer une nouvelle clientèle.</p>
				</div>
				<div className={`introduction-box ${ objectivesSelected.includes("introduction") ? ('box-selected') : ('box-2') }`} id="introduction" onClick={handleObjectiveSelected}>
					<img className="introduction-img" src="/imgs/humans/introduction.png" alt="introduction" />
					<CheckCircle className="absolute top-1 right-1" size="24px" fill={objectivesSelected.includes("introduction")} />
					<p className="introduction-text">Introduire de nouveaux plats.</p>
				</div>
				<div className={`reinforce-box ${ objectivesSelected.includes("reinforce") ? ('box-selected') : ('box') }`} id="reinforce" onClick={handleObjectiveSelected}>
					<img className="reinforce-img" src="/imgs/humans/reinforce.png" alt="reinforce" />
					<CheckCircle className="absolute top-1 right-1" size="24px" fill={objectivesSelected.includes("reinforce")} />
					<p className="reinforce-text">Renforcer votre image de marque.</p>
				</div>
				{ objectivesSelected.length > 0 &&
				<div className="row-span-1 col-start-16 col-span-8 flex justify-end items-end">
					<button className="btn-tuco-uh w-full mt-2 text-black shadow-lg" onClick={handleClickAnimate}>Continuer</button>
				</div>}
			</motion.div>
			<AnimatePresence>
				{ !hasBeenSelected ? (
				<motion.div key="initial" initial={{ opacity:0, rotate:90 }} animate={ under750 ? { opacity:1, x:'-35px', rotate:0 } : { opacity:1, x:'-50px', rotate:0 }}  transition={{ duration: 1.2, delay: 0.5}}
					exit={{ opacity:0, rotate:-360, transition: { duration: 0.5 }}}
					className="fixed bottom-9 left-1/2 transform">
						<div className={`flex justify-center items-center rounded-full bg-tremor-tuco-dark p-2 ${circleSize} shadow-lg shadow-gray-500 border-1 border-tremor-tuco-latte`}>
							<img src='/imgs/emojis/melting.png' alt="smiling face" className={`${emojiSize}`} />
						</div>
				</motion.div> ) : hasBeenSelected && objectivesSelected.length === 0 ? (
				<motion.div key="first" initial={under750 ? { opacity:0, x:'-35px', rotate:360 } : { opacity:0, x:'-50px', rotate:360 }} animate={{ opacity:1, rotate:0 }} transition={{ duration: 1.2, delay: 0.2}}
					exit={{ opacity:0, rotate:-360, transition: { duration: 0.5 }}}
					className="fixed bottom-9 left-1/2 transform">
						<div className={`flex justify-center items-center rounded-full bg-tremor-tuco-dark p-2 ${circleSize} shadow-lg shadow-gray-500 border-1 border-tremor-tuco-latte`}>
							<img src='/imgs/emojis/melting.png' alt="smiling face" className={`${emojiSize}`} />
						</div>
				</motion.div> ) : objectivesSelected.length === 1 && !isExiting ? (
				<motion.div key="second" initial={under750 ? { opacity:0, x:'-35%', rotate:360 } : { opacity:0, x:'-50%', rotate:360 }} animate={{ opacity:1, rotate:0 }} transition={{ duration: 1.2, delay: 0.2}}
					exit={ nextPage ? exitAnimation.nextPage : exitAnimation.deselected }
					className="fixed bottom-9 left-1/2 transform">
						<div className={`flex justify-center items-center rounded-full bg-tremor-tuco-dark p-2 ${circleSize} shadow-lg shadow-gray-500 border-1 border-tremor-tuco-latte`}>
							<img src='/imgs/emojis/smiling.png' alt="smiling face" className={`${emojiSize}`} />
						</div>
				</motion.div>
				) : ( objectivesSelected.length === 2 && !isExiting &&
				<motion.div key="third" initial={under750 ? { opacity:0, x:'-35%', rotate:360 } : { opacity:0, x:'-50%', rotate:360 }} animate={{ opacity:1, rotate:0 }} transition={{ duration: 1.2, delay: 0.2}}
					exit={ nextPage ? exitAnimation.nextPage : exitAnimation.deselected }
					className="fixed bottom-9 left-1/2 transform">
						<div className={`flex justify-center items-center rounded-full bg-tremor-tuco-dark p-2 ${circleSize} shadow-lg shadow-gray-500 border-1 border-tremor-tuco-latte`}>
							<img src='/imgs/emojis/big-smile.png' alt="smiling face" className={`${emojiSize}`} />
						</div>
				</motion.div>)}
			</AnimatePresence>
		</>
	);
}