import React from "react";
import { Tuco } from "../misc/Tuco";

export default function Confidentiality() {
	return (
		<>
			<div className="flex flex-col h-full w-full bg-tremor-tuco-dark">
				<div className="flex flex-col justify-center items-center pt-10">
					<div className="flex justify-center items-center">
						<p className="tuco-title-white text-3xl tablet-min:text-5xl text-center">Politique de confidentialité de TUCO</p>
						<Tuco />
					</div>
					<p className="tuco-text-2-white text-sm tablet-min:tuco-text-2-white text-center">Dernière mise à jour : 21/03/2024</p>
				</div>
				<div className="p-8">
					<p className="tuco-text-white text-center mt-10">Chez TUCO, accessible à tuco-gab.com, l'une de nos principales priorités est la vie privée de nos visiteurs.<br/>Cette Politique de Confidentialité documente les types d'informations personnelles que TUCO recueille et enregistre, ainsi que les manières dont nous les utilisons.<br/>Si vous avez des questions supplémentaires ou avez besoin de plus d'informations sur notre Politique de Confidentialité, n'hésitez pas à nous contacter.</p>
					<p className="tuco-title-white text-2xl tablet-min:text-3xl text-center mt-10">Collecte et Utilisation des Informations Personnelles</p>
					<p className="tuco-text-white text-center mt-10">Nous collectons votre nom, prénom et adresse email lorsque vous remplissez notre formulaire sur le site. Ces informations sont utilisées uniquement pour vous contacter en relation avec la requête ou le service pour lequel vous avez rempli le formulaire. Nous ne vendons, partageons, ni louons ces informations à des tiers de manière que ce soit différente de ce qui est décrit dans cette politique.</p>
					<p className="tuco-title-white text-2xl tablet-min:text-3xl text-center mt-10">Conservation des Données</p>
					<p className="tuco-text-white text-center mt-10">TUCO conservera vos informations personnelles seulement pour la durée nécessaire aux fins énoncées dans cette Politique de Confidentialité. Nous conserverons et utiliserons vos informations dans la mesure nécessaire pour nous conformer à nos obligations légales, résoudre des litiges et appliquer nos accords et politiques.</p>
					<p className="tuco-title-white text-2xl tablet-min:text-3xl text-center mt-10">Sécurité des Données</p>
					<p className="tuco-text-white text-center mt-10">La sécurité de vos données est importante pour nous, mais rappelez-vous qu'aucune méthode de transmission sur Internet, ou méthode de stockage électronique n'est sûre à 100%. Bien que nous nous efforcions d'utiliser des moyens commercialement acceptables pour protéger vos informations personnelles, nous ne pouvons pas garantir leur sécurité absolue.</p>
					<p className="tuco-title-white text-2xl tablet-min:text-3xl text-center mt-10">Vos Droits de Confidentialité</p>
					<p className="tuco-text-white text-center mt-10">Vous avez le droit de demander l'accès, la correction ou la suppression de vos informations personnelles que nous détenons. Vous pouvez également vous opposer au traitement de vos données ou demander une limitation de ce traitement. Si vous avez donné votre consentement à la collecte, au traitement et à la transmission de vos données personnelles, vous avez le droit de retirer votre consentement à tout moment, sans affecter la légalité du traitement basé sur le consentement avant son retrait.</p>
					<p className="tuco-title-white text-2xl tablet-min:text-3xl text-center mt-10">Modifications de la Politique de Confidentialité</p>
					<p className="tuco-text-white text-center mt-10">Nous pouvons mettre à jour notre Politique de Confidentialité de temps à autre. Nous vous notifierons de tout changement en publiant la nouvelle Politique de Confidentialité sur cette page. Nous vous conseillons de consulter cette Politique de Confidentialité périodiquement pour tout changement.</p>
					<p className="tuco-title-white text-2xl tablet-min:text-3xl text-center mt-10">Contactez-nous</p>
					<p className="tuco-text-white text-center mt-10">Si vous avez des questions ou des suggestions concernant notre Politique de Confidentialité, n'hésitez pas à nous contacter à l'adresse suivante : menu@tuco-gab.com</p>
				</div>
				<div className="flex justify-center items-center">
					<Tuco />
					<p className="tuco-text-2-white text-center">© 2024 TUCO</p>
				</div>
			</div>
		</>
	);
}