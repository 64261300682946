import React, { createContext, useState, useContext } from 'react';
import { RiCheckLine, RiCloseLine } from "@remixicon/react";


const GlobalContext = createContext();

export const useGlobal = () => useContext(GlobalContext);

export const GlobalProvider = ({ children }) => {

	const [navbarExpanded, setNavbarExpanded] = useState(false);
	const [showAlert, setShowAlert] = useState("none");
	const [alertMessage, setAlertMessage] = useState("");
	
	const stateColors = {
		'prospect': '#58af4c',
        'maquette': '#a16c40',
        'prerdv': '#5fada3',
        'rdv': '#388a7f',
        'non': '#a13f2b',
        'predev': '#ba922b',
        'dev': '#a9ad1f',
        'menu': '#bd3fbf',
        'send': '#338a60',
        'print': '#bf7521',
        'livre': '#af5c4c',
        'waiting': '#0a1591',
        'done': '#4CAF50',
	}

	const stateNames = {
		'prospect': 'Prospect',
        'maquette': 'Maquette a faire',
        'prerdv': 'RDV a prévoir',
        'rdv': 'RDV fait',
        'non': 'Client non intéressé',
        'predev': 'Devis a faire',
        'dev': 'En attente de paiement',
        'menu': 'Creation du menu',
        'send': 'En attente de validation client',
        'print': 'À imprimer',
        'livre': 'À livrer',
        'waiting': 'En attente d\'avis client',
        'done': 'Travail terminé',
	}

	const stateItems = [
		{label: 'Maquette a faire', value: 'maquette'},
		{label: 'RDV a prévoir', value: 'prerdv'},
		{label: 'RDV fait', value: 'rdv'},
		{label: 'Client non intéressé', value: 'non'},
		{label: 'Devis a faire', value: 'predev'},
		{label: 'En attente de paiement', value: 'dev'},
		{label: 'Creation du menu', value: 'menu'},
		{label: 'En attente de validation client', value: 'send'},
		{label: 'À imprimer', value: 'print'},
		{label: 'À livrer', value: 'livre'},
		{label: 'En attente d\'avis client', value: 'waiting'},
		{label: 'Travail terminé', value: 'done'},
	]

	const states = useState([
		'maquette',
		'prerdv',
		'rdv',
		'non',
		'predev',
		'dev',
		'menu',
		'send',
		'print',
		'livre',
		'waiting',
		'done',
	]);

	const getPreviousState = (state) => {
		const states = [
			'maquette',
			'prerdv',
			'rdv',
			'non',
			'predev',
			'dev',
			'menu',
			'send',
			'print',
			'livre',
			'waiting',
			'done',
		];
		const index = states.indexOf(state);
		if (index === 0) {
			return state;
		}
		return states[index - 1];
	}

	const getNextState = (state) => {
		const states = [
			'maquette',
			'prerdv',
			'rdv',
			'non',
			'predev',
			'dev',
			'menu',
			'send',
			'print',
			'livre',
			'waiting',
			'done',
		];
		const index = states.indexOf(state);
		if (index === states.length - 1) {
			return state;
		}
		return states[index + 1];
	}

	const styleNames = {
		'[\'bistro\']': "Bistro - Café",
		'[\'high\']': "Haut de gamme",
		'[\'fastfood\']': "Fast-food",
		'[\'world\']': "Cuisine du monde",
	}

	const objectivesNames = {
		'ticket': "Augmenter le ticket moyen",
		'upgrade': "Améliorer la satisfaction client",
		'attirate': "Attirer une nouvelle clientèle",
		'introduction': "Introduire de nouveaux plats",
		'reinforce': "Renforcer votre image de marque",
	}

	const UnValid = () => {
		return (
			<RiCloseLine color="#FF0000" />
		)
	}

	const Valid = () => {
		return (
			<RiCheckLine color="#64CD8A" />
		)
	}

	const checkDataEmpty = (data) => {
		if (data === undefined || data === null || data === "") {
			return <UnValid />
		}
		return data;
	}

	const checkDataValid = (data) => {
		if (data === undefined || data === null || data === "") {
			return <UnValid />
		}
		return <Valid />
	}

	const handleObjectivesPrint = (objectives) => {
		if (objectives.length === 0 || objectives === undefined || objectives === null || objectives === "" || Object.keys(objectives).length === 0) {
			return <UnValid />;
		}
		const objectivesTexts = objectives.map(objective => objectivesNames[objective] || objective);
		const objectivesString = objectivesTexts.join(' - ');
		return (
			<p className="tuco-text">{objectivesString}</p>
		);
	}

	const handleTypePrint = (type) => {
		if (type === undefined || type === null || type === "") {
			return <UnValid />
		}
		return (
			<p className="tuco-text text-center">{styleNames[type]}</p>
		);
	}

	const handleAdjectivesPrint = (adjectives) => {
		if (adjectives.length === 0 || adjectives === undefined || adjectives === null || adjectives === "" || Object.keys(adjectives).length === 0) {
			return <UnValid />;
		}
		const adjectivesString = adjectives.join(' - ');
		return (
			<p className="tuco-text">{adjectivesString}</p>
		);
	}

	const state = {
		stateColors,
		stateNames,
		styleNames,
		states,
		checkDataEmpty,
		checkDataValid,
		UnValid,
		Valid,
		handleObjectivesPrint,
		handleTypePrint,
		handleAdjectivesPrint,
		navbarExpanded,
		setNavbarExpanded,
		stateItems,
		showAlert,
		setShowAlert,
		alertMessage,
		setAlertMessage,
		getPreviousState,
		getNextState
	}

	return (
		<GlobalContext.Provider value={state}>
			{children}
		</GlobalContext.Provider>
	);
}