import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Homepage from './homepage';
import Toolspage from './toolspage';
import Databases from './databases';
import DatabaseDetail from './databaseDetail';
import FirstContact from './forms/FirstContact';
import RestauForm1 from './forms/RestauForm-1';
import RestauForm2 from './forms/RestauForm-2';
import RestauForm3 from './forms/RestauForm-3';
import RestauForm4 from './forms/RestauForm-4';
import FormDone from './forms/FormDone';
import FormDetails from './formDetails';
import Landing from './website/Landing';
import Confidentiality from './website/Confidentiality';
import AddContact from './website/AddContact';
import ClientsPage from './ClientsPage';
import ClientsDetails from './ClientsDetails';


const AdminRoutes = () => {
	const location = useLocation();

	return (
		<AnimatePresence mode="sync">
			<Routes location={location} key={location.pathname}>
				<Route path="/" element={<Homepage />} />
				<Route path="/tools" element={<Toolspage />} />
				<Route path="/databases/instagram" element={<Databases />} />
				<Route path="/database/:id" element={<DatabaseDetail />} />
				<Route path="/forms/firstcontact" element={<FirstContact />} />
				<Route path="/forms/restau" element={<RestauForm1 />} />
				<Route path="/forms/restau/2" element={<RestauForm2 />} />
				<Route path="/forms/restau/3" element={<RestauForm3 />} />
				<Route path="/forms/restau/4" element={<RestauForm4 />} />
				<Route path="/forms/done" element={<FormDone />} />
				<Route path="/forms/details" element={<FormDetails />} />
				<Route path="/website" element={<Landing />} />
				<Route path="/website/confidentiality" element={<Confidentiality />} />
				<Route path="/website/addcontact" element={<AddContact />} />
				<Route path="/clients" element={<ClientsPage />} />
				<Route path="/clients/:id" element={<ClientsDetails />} />
			</Routes>
		</AnimatePresence>
	);
}

export default AdminRoutes;