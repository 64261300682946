import React from "react";

export const Tuco = ({ size = "75px" }) => (
	<div className="w-fit ht-fit flex justify-center items-center">
		<img
			alt="Tuco logo"
			src="https://i.ibb.co/RpYVL8W/logo-final.png"
			style={{ width: size, height: size}}
		/>
	</div>
);
