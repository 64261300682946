import React, {useState, useEffect, useCallback } from "react";
import { Card, CardHeader, CardBody, CardFooter, Divider } from "@nextui-org/react";
import { useGlobal } from "./globalContext";

const TempClientCard = ({ data }) => {

	console.log(data[0]);
	const { restaurantName, adjectives, objectives, style, concept, identity, lastName, firstName, email, phone } = data[0];
	const { checkDataEmpty, checkDataValid } = useGlobal();

	const InfoGrid = ({ lastName, firstName, email, phone }) => {
		return (
			<div className="grid grid-cols-2 gap-2">
				<div className="flex flex-col justify-center items-center">
					<p className="tuco-text underline">Nom</p>
					<p className="tuco-undertext">{checkDataEmpty(lastName)}</p>
				</div>
				<div className="flex flex-col justify-center items-center">
					<p className="tuco-text underline">Prénom</p>
					<p className="tuco-undertext">{checkDataEmpty(firstName)}</p>
				</div>
				<div className="flex flex-col justify-center items-center">
					<p className="tuco-text underline">Email</p>
					<p className="tuco-undertext">{checkDataEmpty(email)}</p>
				</div>
				<div className="flex flex-col justify-center items-center">
					<p className="tuco-text underline">Téléphone</p>
					<p className="tuco-undertext">{checkDataEmpty(phone)}</p>
				</div>
			</div>
		);
	}


	const InfoGrid2 = ({ adjectives, objectives, style, concept }) => {
		return (
			<div className="grid grid-cols-2 gap-2">
				<div className="flex flex-col justify-center items-center">
					<p className="tuco-text underline">Adjectifs</p>
					<p className="tuco-undertext">{checkDataValid(adjectives)}</p>
				</div>
				<div className="flex flex-col justify-center items-center">
					<p className="tuco-text underline">Objectifs</p>
					<p className="tuco-undertext">{checkDataValid(objectives)}</p>
				</div>
				<div className="flex flex-col justify-center items-center">
					<p className="tuco-text underline">Type</p>
					<p className="tuco-undertext">{checkDataValid(style)}</p>
				</div>
				<div className="flex flex-col justify-center items-center">
					<p className="tuco-text underline">Concept</p>
					<p className="tuco-undertext">{checkDataValid(concept)}</p>
				</div>
				<div className="flex flex-col col-span-2 justify-center items-center">
					<p className="tuco-text underline">Identité</p>
					<p className="tuco-undertext">{checkDataValid(identity)}</p>
				</div>
			</div>
		);
	}

	return (
		<Card className="w-full">
			<CardHeader className="flex justify-center items-center">
				<p className="tuco-undetitle-2 font-bold">{restaurantName}</p>
			</CardHeader>
			<Divider />
			<CardBody className="flex justify-center items-center">
				<InfoGrid lastName={lastName} firstName={firstName} email={email} phone={phone} />
				<InfoGrid2 adjectives={adjectives} objectives={objectives} style={style} concept={concept} />
			</CardBody>
		</Card>
	);
}

export default TempClientCard;